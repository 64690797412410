import { Input } from "antd";
import { useState, useEffect } from "react"


export default function EquipmentAndDetailed (props) {

    const { detailInfo, setDetailInfo, iconList, setIconList } = props


    const selectEquipment = (item) => {
        let arr = iconList;
        arr.map((e)=>{
            if (e.name === item.name) {
                e.isSelected = !e.isSelected
            }
        })
        setIconList([...arr])
    }

    const valueChange = (val,type) => {
        let obj = detailInfo;
        obj[type] = val
        setDetailInfo({...obj})
    }

    return <div className="EquipmentAndDetailed">
        <div className="icon-list">
            {
                iconList.map((e,i)=>(
                    <div className={`list-item ${e.isSelected && 'list-item-active'}`} key={i} onClick={()=>selectEquipment(e)}>
                        {
                            e.isSelected ? <img className="icon" src={require('../../assets/images/property/'+e.activeIcon)} /> : <img className="icon" src={require('../../assets/images/property/'+e.icon)} />
                        }
                        <div className="text">{e.name}</div>
                    </div>
                ))
            }
        </div>
        <div className="inp-list">
            <div className="inp-item">
                <div className="title">設備</div>
                <Input value={detailInfo.equipment} onChange={(e)=>valueChange(e.target.value,'equipment')} />
            </div>
            <div className="inp-item">
                <div className="title">契約期間</div>
                <Input value={detailInfo.contract} onChange={(e)=>valueChange(e.target.value,'contract')} />
            </div>
            <div className="inp-item">
                <div className="title">現況</div>
                <Input value={detailInfo.statusQuo} onChange={(e)=>valueChange(e.target.value,'statusQuo')} />
            </div>
            <div className="inp-item">
                <div className="title">引渡し</div>
                <Input value={detailInfo.extradition} onChange={(e)=>valueChange(e.target.value,'extradition')} />
            </div>
        </div>
    </div>
}

