import Cropper from 'cropperjs'
import { useState, useEffect, useRef } from 'react';
import 'cropperjs/dist/cropper.css';
import { Button } from 'antd';

export default function ImageCropper (params) {

    const { image, img, setImg, setStartCrop, setCropModal } = params;
    const imageRef = useRef(null);
    const cropperRef = useRef(null);

    let [cropImg,setCropImg] = useState()


    const cropHandle = () => {
      const cropper = cropperRef.current
      let cropBox = cropper.getCropBoxData()
      let imgObj = cropper.getCroppedCanvas({
        maxWidth: 4096,
        maxHeight: 4096,
        imageSmoothingQuality: "high"
      })
      const croppedImageURL = imgObj.toDataURL();
      setImg(croppedImageURL)
      // setStartCrop(true)
      setCropModal(false)
    }

    useEffect(() => {
      cropperRef.current = new Cropper(imageRef.current, {
          // Cropper.js的配置选项
          aspectRatio: 16 / 9,
          autoCrop: true,
          initialAspectRatio: 16 / 9,
          responsive: true,
          viewMode:1,
          autoCropArea:1,
          autoCrop: false,
          scalable: false,
          minCropBoxWidth: 240,
          movable: false,
          zoomable: false,
          rotatable: false,
          scalable: false,
          // crop: () => {
          //   // 获取裁剪后的图片数据
          //   const canvas = cropper.getCroppedCanvas();
          //   const croppedImage = canvas.toDataURL('image/jpeg');
          //   params.onCrop(croppedImage);
          //   cropImg = croppedImage;
          //   setCropImg(cropImg);
          // },
          ready: () => {
              let imgInfo = cropperRef.current.getImageData();
              let cropperContainer = cropperRef.current.getContainerData()
              cropperRef.current.setCanvasData({
                left: 0,
                top: 0,
                width: imgInfo.width,
                height: imgInfo.height
              })
              
              let containerWidth = cropperContainer.width
              let containerHeight = containerWidth / imgInfo.aspectRatio;
              // console.log(document.getElementsByClassName('cropper-container')[0]);
              document.getElementsByClassName('cropper-container')[0].style.height = containerHeight + 'px';
              cropperRef.current.initialCropBoxData = { // 设置初始裁剪框的尺寸和位置
                left: 0,
                top:  0,
                width: 320,
                height: 160
              }
              cropperRef.current.crop()
          }
        });
        // 组件销毁时销毁Cropper实例
        const imgObj = cropperRef.current.getImageData();
        return () => {
          cropperRef.current.destroy();
        };
      }, [image]);

    return <div className='ImageCropper'>
        <img ref={imageRef} 
            src={image}
        />
        {
            cropImg && <img src={cropImg} />
        }
        <Button onClick={()=>setCropModal(false)} style={{marginRight: "20px"}}>取消</Button>
        <Button onClick={cropHandle} type='primary'>确认</Button>
    </div>
}



