import { Upload } from "antd"
import UploadImg from "../Upload/UploadImg"
import { useEffect } from "react"


export default function Surroundings (params) {
    const { 
        data, 
        setData, 
        edit, 
        imgList, 
        setImgList, 
        imageDesc, 
        setImageDesc,
        surroundingsInfo,
        setSurroundingsInfo,
        hashList,
        setHashList
    } = params

    useEffect(()=>{
        console.log("surroundings====",data);
    },[data])

    return <div className="Surroundings">
        <h3>周边环境</h3>
        <UploadImg 
            type='property' 
            step="surroundings"
            setHash={setData} 
            hash={data} 
            edit={edit} 
            imgList={imgList}
            setImgList={setImgList}
            imageDesc={imageDesc}
            setImageDesc={setImageDesc}
            surroundingsInfo={surroundingsInfo}
            setSurroundingsInfo={setSurroundingsInfo}
            hashList={hashList}
            setHashList={setHashList}
        />
    </div>
}

