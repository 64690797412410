import { message } from "antd"


export const resParse = (res) => {
    if (res.code === 0) {
        return res.data
    }else {
        message.warning(res.msg)
        return null
    }
}