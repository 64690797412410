import serviceAxios from "..";

const token = localStorage.getItem('btl-token');

export const createRoomInfo = (data) => {

    return serviceAxios({
        url: `/suite/create`,
        method: "post",
        data,
        headers: {
            'x-token': token
        }
    })
}

// 获取房屋列表
export const getRoomList = (data) => {
    const { page, pageSize, estateID } = data
    return serviceAxios({
        url: `/suite/list?page=${page}&pageSize=${pageSize}&estateID=${estateID}`,
        method: 'get',
        headers: {
            'x-token': token
        }
    })
}

// 更新房屋信息
export const updateRoomInfo = (data) => {
    return serviceAxios({
        url: `/suite/update`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

// 删除房屋信息
export const deleteRoomInfo = (data) => {

    return serviceAxios({
        url: `/suite/delete`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

// 更改房屋满员状态
export const updateFull = (data) => {
    return serviceAxios({
        url: `/suite/full`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

// 更改房屋状态
export const updateStatusInfo = (data) => {
    return serviceAxios({
        url: `/suite/status`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

// 预约记录列表
export const getRecordListInfo = (data) => {
    const { page, pageSize } = data
    return serviceAxios({
        url: `/appointment/list?page=${page}&pageSize=${pageSize}`,
        method: 'get',
        headers: {
            'x-token': token
        }
    })
}

// 更新预约记录状态
export const updateRecordStatus = (data) => {

    return serviceAxios({
        url: `appointment/status`,
        method: "post",
        headers: {
            'x-token': token
        },
        data
    })
}

// 修改预约记录备注信息
export const updateRemark = (data) => {
    return serviceAxios({
        url: `appointment/update`,
        method: "post",
        headers: {
            'x-token': token
        },
        data
    })
}

// 更新房屋权限
export const updateWeights = (data) => {
    return serviceAxios({
        url: `suite/weight`,
        method: "post",
        headers: {
            'x-token': token
        },
        data
    })
}

