import { Input, Select, Button, message, InputNumber, Switch } from "antd";
import UploadImg from "../../components/Upload/UploadImg";
import '../../style/Room.scss';
import { useEffect, useState } from "react";
import { getAllProperty } from "../../request/api/property";
import { createRoomInfo, updateRoomInfo } from "../../request/api/room";
import { useNavigate } from "react-router-dom";
import { uploadImg } from "../../request/api/property";


export default function CreateRoom (params) {
    const navigate = useNavigate()
    const {edit,rowData, setCreateModal, checkProperty} = params

    let [data,setData] = useState({
        property: 0,
        floor: "",
        room: "",
        area: "",
        desc: "",
        details: '',
        rental: ""
    })
    let [isFull,setIsFull] = useState(false)
    let [propertyList,setPropertyList] = useState([]);
    // 物业图片展示
    let [propertyImg,setPropertyImg] = useState()
    // 物业名字
    let [propertyName,setPropertyName] = useState('请选择物业')
    // 图片hash
    let [imgHash,setImgHash] = useState();
    // 输入框状态
    let [inpStatus,setInpStatus] = useState({
        floor: "",
        room: "",
        area: "",
        detail: "",
        desc: "",
        rental: ""
    })
    // 图片列表
    let [imgList,setImgList] = useState([])
    // 户型图hash
    let [houseTypeHash,setHouseTypeHash] = useState()
    let [houseTypeList,setHouseTypeList] = useState([])
    let [hashList,setHashList] = useState([]);
    let [uidArr,setUidArr] = useState([])
    // 上传图片状态
    let [uploadStatus,setUploadStatus] = useState(false)


    // const propertyList = [
    //     {
    //         label: "假物业",
    //         value: "noProperty"
    //     }
    // ]

    const valueChange = (type,val) => {
        data[type] = val;
        setData({...data})
    }

    const getProperty = async () => {
        await getAllProperty()
        .then((res) => {
            if (res.code === 0 && res.data.list) {
                let arr = [{
                    value: 0,
                    label: '请选择物业',
                    img: 'xxx/xxx'
                }]
                res.data.list.map((e)=>{
                    arr.push({
                        value: e.id,
                        label: e.name,
                        img: `${process.env.REACT_APP_API_URL}/${e.image}`
                    })
                })
                propertyList = arr;
                setPropertyList([...propertyList])

            }
        }).catch((err) => {
            
        });
    }

    const propertyChange = (val) => {
        data.property = val;
        setData({...data})
    }

    const create = async () => {
        let obj = {
            estateID: data.property,
            floor: data.floor,
            suiteNO: data.room,
            area: data.area,
            images: imgHash,
            // description: data.desc,
            details: data.details,
            rental: data.rental,
            layout: houseTypeHash,
            weight:1
        }
        if (!obj.floor) {
            message.error("楼层不能为空")
            inpStatus.floor = "error";
            setInpStatus({...inpStatus})
        }else if (!obj.suiteNO) {
            message.error("房间号不能为空")
            inpStatus.room = "error";
            setInpStatus({...inpStatus})
        }else if (!obj.area) {
            message.error("房间面积不能为空")
            inpStatus.area = "error";
            setInpStatus({...inpStatus})
        }else if (!obj.details) {
            message.error("房屋简介不能为空")
            inpStatus.detail = "error";
            setInpStatus({...inpStatus})
        }
        // else if (!obj.description) {
        //     message.error("房屋描述不能为空")
        //     inpStatus.desc = "error";
        //     setInpStatus({...inpStatus})
        // }
        else if (!obj.rental) {
            message.error("房屋租金不能为空")
            inpStatus.rental = "error"
            setInpStatus({...inpStatus})
        }else {
            await createRoomInfo(obj)
            .then((res) => {
                if (res.code === 0) {
                    message.success(res.msg);
                    setTimeout(()=>{
                        navigate(0)
                    },1000)
                }else{
                    message.error(res.msg)
                }
            }).catch((err) => {
                
            });
        }
    }

    const editHandle = async () => {
        let obj = {
            id: rowData.id,
            estateID: data.property,
            floor: data.floor,
            suiteNO: data.room,
            area: data.area,
            images: imgHash,
            // description: data.desc,
            details: data.details,
            weight:1,
            isPublish: false,
            full: isFull,
            layout: houseTypeHash,
            rental: data.rental
        }
        if (!obj.floor) {
            message.error("楼层不能为空")
            inpStatus.floor = "error";
            setInpStatus({...inpStatus})
        }else if (!obj.suiteNO) {
            message.error("房间号不能为空")
            inpStatus.room = "error";
            setInpStatus({...inpStatus})
        }else if (!obj.area) {
            message.error("房间面积不能为空")
            inpStatus.area = "error";
            setInpStatus({...inpStatus})
        }else if (!obj.details) {
            message.error("房屋简介不能为空")
            inpStatus.detail = "error";
            setInpStatus({...inpStatus})
        }
        // else if (!obj.description) {
        //     message.error("房屋描述不能为空")
        //     inpStatus.desc = "error";
        //     setInpStatus({...inpStatus})
        // }
        else if (!obj.rental) {
            message.error("房屋租金不能为空")
            inpStatus.rental = "error"
            setInpStatus({...inpStatus})
        }else{
            await updateRoomInfo(obj)
            .then((res) => {
                if (res.code === 0) {
                    message.success(res.msg);
                    setTimeout(()=>{
                        navigate(0)
                    },1000)
                }else {
                    message.error(res.msg)
                }
            }).catch((err) => {
                
            });
        }
    }

    const statusChange = (type) => {
        inpStatus[type] = true;
        setInpStatus({...inpStatus})
    }

    const editHash = (step,arr,isUpload) => {
        let str = ''
        if (step) {

        }else{
          if (arr.length > 0) {
            arr.map((e)=>{
                if (str) {
                    str = `${str},${e}`
                }else {
                    str = `${e}`
                }
            })
            imgHash = str
            setImgHash(imgHash)
          }
        }
    }

    const uploadImage = async (step) => {
        const formData = new FormData();
        let uidArr = [];
        let uidAndUrl = []
        imgList.forEach((file)=>{
            uidArr.push(file.uid)
            if (file.originFileObj) {
              formData.append("file",file.originFileObj)
            }else{
              formData.append("file",file)
            }
        })
        await uploadImg(formData)
        .then((res) => {
            if (res.code === 0) {
                let arr = []
                imgList.map((e)=>{
                    if (e.url) {
                    let nArr = e.url.split('/')
                    let str = `${nArr[3]}/${nArr[4]}/${nArr[5]}`
                    arr.push(str)
                    }
                });
                if (res.data) {
                    let length = uidAndUrl.length
                    res.data.map((e,i)=>{
                    uidAndUrl.push({
                        uid: edit ? uidArr[length + i] : uidArr[i],
                        url: e
                    })
                    })
                    setUidArr([...uidAndUrl])
                    let newArr = arr.concat(res.data)
                    setHashList([...newArr])
                    editHash(step,newArr,"upload")
                }else{
                    setUidArr([...uidAndUrl])
                    setHashList([...arr])
                    editHash(step,arr,"noUpload")
                }
                setUploadStatus(true)
            }
        }).catch((err) => {
            
        });
    }

    // const fullChange = () => {
    //     isFull = !isFull;
    //     setIsFull(isFull)
    // }

    useEffect(()=>{
        getProperty()
    },[])

    useEffect(()=>{
        if (edit && rowData) {
            console.log("rowData===",rowData);
            data.property = rowData.estate.id;
            data.floor = rowData.floor;
            data.room = rowData.suiteNO;
            data.area = rowData.area;
            data.desc = rowData.description;
            data.details = rowData.detail;
            data.rental = rowData.rental
            isFull = rowData.full;
            imgHash = rowData.images;
            houseTypeHash = rowData.layout;
            setHouseTypeHash(houseTypeHash)
            setData({...data});
            setImgHash(imgHash)
            setIsFull(isFull)
        }
    },[rowData])

    useEffect(()=>{
        if (data.property === 0) {
            setPropertyImg('')
        }else{
            propertyList.map((e,i)=>{
                if (e.value === data.property) {
                    propertyImg = e.img
                    setPropertyImg(propertyImg)
                    propertyName = e.label;
                    setPropertyName(propertyName)
                }
            })
        }
    },[data.property,propertyList])


    useEffect(()=>{
        if (checkProperty) {
            data.property = Number(checkProperty);
            setData({...data})
        }
    },[checkProperty])

    useEffect(()=>{
        if (uploadStatus) {
            if (edit) {
                editHandle()
            }else{
                create()
            }
        }
    },[uploadStatus])


    return <div className="CreateRoom">
        <div className="create-title">
            <h1>创建房间</h1>
        </div>
        <div className="create-content">
            <div className="content-left">
                <div className="select room-item">
                    <div>
                        <h3>选择物业</h3>
                        <Select 
                            options={propertyList}
                            value={data.property}
                            onChange={propertyChange}
                            style={{
                                width: 550
                            }}
                        />
                    </div>
                </div>
                <div className="room-item">
                    <div className="floor">
                        <h3>楼层</h3>
                        <div className="m165">
                            <Input 
                                style={{width: 165, marginRight: 15}}
                                value={data.floor}
                                status={inpStatus.floor}
                                onFocus={()=>statusChange("floor")}
                                onChange={(e)=>valueChange("floor",e.target.value)}
                            />
                            <span>层</span>
                        </div>
                    </div>
                    <div className="room">
                        <h3>房间号</h3>
                        <div>
                            <Input 
                                style={{width: 165, marginRight: 15}} 
                                value={data.room}
                                status={inpStatus.room}
                                onFocus={()=>statusChange("room")}
                                onChange={(e)=>valueChange("room",e.target.value)}
                            />
                            <span>室</span>
                        </div>
                    </div>
                </div>
                <div className="room-item">
                    <div className="area">
                        <h3>面积</h3>
                        <div className="m165">
                            <InputNumber 
                                style={{width: 165, marginRight: 15}} 
                                value={data.area}
                                status={inpStatus.area}
                                onFocus={()=>statusChange("area")}
                                controls={false}
                                onChange={(e)=>valueChange("area",e)}
                            />
                            <span>㎡</span>
                        </div>
                    </div>
                    <div className="rental">
                        <h3>租金</h3>
                        <div>
                            <InputNumber 
                                style={{width: 165, marginRight: 15}} 
                                value={data.rental}
                                status={inpStatus.rental}
                                onFocus={()=>statusChange("rental")}
                                controls={false}
                                onChange={(e)=>valueChange("rental",e)}
                            />
                            <span>円</span>
                        </div>
                    </div>
                </div>
                <div className="room-item">
                    <div className="introduction">
                        <h3>房屋简介</h3>
                        <div>
                            <Input.TextArea 
                                style={{width: 600, height: 80}} 
                                value={data.details}
                                status={inpStatus.detail}
                                onFocus={()=>statusChange("detail")}
                                onChange={(e)=>valueChange("details",e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="room-item">
                    <div className="desc">
                        <h3>房屋描述</h3>
                        <div>
                            <Input.TextArea 
                                style={{width: 600, height: 80}} 
                                value={data.desc}
                                status={inpStatus.desc}
                                onFocus={()=>statusChange("desc")}
                                onChange={(e)=>valueChange("desc",e.target.value)}
                            />
                        </div>
                    </div>
                </div> */}
                <div className="room-item">
                    <div>
                        <h3>房屋户型图</h3>
                        <UploadImg 
                            type='personal' 
                            hash={houseTypeHash} 
                            setHash={setHouseTypeHash} 
                            edit={edit} 
                            imgList={houseTypeList} 
                            setImgList={setHouseTypeList} 
                            uploadImage={uploadImage}
                        />
                    </div>
                </div>
                {/* {
                    edit && <div className="room">
                        <h3>是否满室</h3>
                        <div>
                            <Switch checked={isFull} onChange={fullChange} />
                        </div>
                    </div>
                } */}
                <div className="room-item">
                    <div>
                        <h3>房间照片</h3>
                        <UploadImg 
                            type='room' 
                            hash={imgHash} 
                            setHash={setImgHash} 
                            edit={edit} 
                            imgList={imgList} 
                            setImgList={setImgList} 
                            hashList={hashList}
                            setHashList={setHashList}
                        />
                    </div>
                </div>
            </div>
            <div className="content-right">
                <div className="property-img">
                    {
                        propertyImg ? <img src={propertyImg} />
                        :
                        <img src={require('../../assets/images/img/defaultImg.png')} />
                    }
                </div>
                <h2 className="property-name">{propertyName}</h2>
                {/* <p className="property-desc">提供多种类型创建模式，满足不同业务场景需求，可支持小程序一键部署到云服务器</p> */}
            </div>
        </div>
        <div className='create-footer'>
            {
                edit ? '' : <Button onClick={()=>setCreateModal(false)}>取消</Button>
            }
            {
                edit ? <Button type='primary' onClick={()=>uploadImage()}>修改</Button>
                : <Button type='primary' onClick={()=>uploadImage('')}>创建</Button>
            }
        </div>
    </div>
}


