import { Breadcrumb, Layout, Menu, Dropdown, message, Modal, Avatar } from "antd";
import {LogoutOutlined} from '@ant-design/icons'
import { useEffect, useState } from "react";
import AppMenu from "./AppMenu";
import { menus } from "./menu";
import "@/assets/styles/layout.scss"
import '../style/defaultLayout.scss'
import { useRoutes } from "react-router-dom";
import routes from "../router";
import { useNavigate, useLocation } from "react-router-dom";


const getMenu = menu => {
    let newMenu,
      auth = JSON.parse(localStorage.getItem("user"))?.auth;
    if (!auth) {
      return menu;
    } else {
      newMenu = menu.filter(res => res.auth && res.auth.indexOf(auth) !== -1);
      return newMenu;
    }
};

const DefaultLayout = () => {
    const navigateTo = useNavigate()
    const {confirm} = Modal
    const location = useLocation();


    const [collapsed, setCollapsed] = useState(false);
    const { Header, Content, Footer, Sider } = Layout;
    const outlet = useRoutes(routes);
    const user = JSON.parse(localStorage.getItem("btl-user"));


    const [menu] = useState(prevState => {
        let arr = menus.filter(item => {
          // 初始化
          return (localStorage.getItem("authorityId") || [0]).includes(item.auth);
        });

        return getMenu(arr);
    });

    if (location.pathname === "/login") {
      return outlet
    }

    const dropItem = [
      {
        key: '3',
        label: (
          <a>{'退出登录'}</a>
        ),
        icon: <LogoutOutlined />,
        onClick: () => showLoginout()
    },
    ]

    const items = [
      {
        key: "3",
        label: (
          <span className="userTitle">用户设置</span>
        )
      },
      {
        key: "2",
        label: (
          <div className="dropBox">
            <img src={require('../assets/images/icon/personal.png')} />
            <a onClick={()=>navigateTo("/Personal")}>
              个人资料
            </a>
          </div>
        )
      },
      {
        key: '1',
        label: (
          <div className="dropBox">
            <img src={require('../assets/images/icon/exit.png')} />
            <a onClick={() => showLoginout()}>
              退出登陆
            </a>
          </div>
        ),
      },
      
    ];

    const showLoginout = () => {
      confirm({
        title: '确认登出?',
        content: '登出后需重新输入密码',
        okText: '确认',
        cancelText: "取消",
        onOk() {
          loginOut();
        }
      });
    };

    const loginOut = () => {
      localStorage.removeItem("btl-user");
      localStorage.removeItem("btl-token");
      localStorage.removeItem("authorityId")
      setTimeout(() => {
        navigateTo("/login")
      }, 500);
      message.success("登出成功!");
    };


    return <Layout
    style={{
      minHeight: '100vh',
    }}
    className="app"
  >
    <Sider style={{paddingTop: 68, backgroundColor: '#fff'}} className="aside" collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>

      <AppMenu menu={menu} />
    </Sider>
    
    <Layout className="site-layout">
      <Header
        style={{
          padding: 0,
          background: "white",
          height: 48,
          width: '100%',
          position: "fixed",
          top: 0,
          backgroundColor: '#002947',
          zIndex: '2'
        }}
      >
        <div className="header-logo">
          <img src={require('../assets/images/img/logo.png')} />
        </div>
        <div className="header-user">
            {/* <Dropdown menu={dropItem} overlayStyle={{ width: "20rem" }}>
                
            </Dropdown> */}
            <Dropdown
              menu={{
                items,
              }}
              className="userDropdown"
              getPopupContainer={triggerNode => triggerNode.parentNode}
            >
              <div>
                {/* <div className="user-avator">
                  {user.avatar ? <img src={`${process.env.REACT_APP_API_URL}/${user.avatar}`} /> : ""}
                </div> */}
                <Avatar
                  src={`${process.env.REACT_APP_API_URL}/${user.avatar}`}
                  alt="avatar"
                  style={{ cursor: "pointer" }}
                  className="user-avator"
                />
                <div className="user-name">{user.nickname ? user.nickname : "用户名"}</div>
              </div>
            </Dropdown>
        </div>
      </Header>
      <Content
        style={{
          margin: '60px 16px 0 215px',
        }}
      >
        {outlet}
      </Content>
      {/* <Footer
        style={{
          textAlign: 'center',
        }}
      >
        Ant Design ©2023 Created by Ant UED
      </Footer> */}
    </Layout>
  </Layout>

}


export default DefaultLayout;