// const Login = require("@/views/Login")
// import {Navigate} from "react-router-dom"
import Login from "../views/Login"
import Index from "../views/Index"
import CreateBanner from "../views/Banner/CreateBanner";
import BannerList from "../views/Banner/BannerList";
import CreateProperty from "../views/Property/CreateProperty";
import PropertyList from "../views/Property/PropertyList";
import CreateRoom from "../views/Room/CreateRoom";
import RoomList from "../views/Room/RoomList";
import CreateCompany from "../views/Company/CreateCompany";
import CompanyList from "../views/Company/CompanyList";
import RecordList from "../views/Record/RecordList";
import UserManagement from "../views/UserManagement/UserManagement";
import Personal from "../views/Personal/Personal";
// const Login = lazy(()=>import("../views/Login"))

const routes = [
    { 
      path: "/",
      element: <CompanyList />
    },
    // 公告 ===>
    {
      path: "/login",
      element: <Login />
    },
    {
      path: "/banner",
      children: [
        {
          path: 'bannerList',
          element: <BannerList />
        },
        {
          path: "createBanner",
          element: <CreateBanner />
        }
      ]
    },
    {
      path: '/property',
      children: [
        {
          path: 'propertyList',
          element: <PropertyList />
        },
        {
          path: "createProperty",
          element: <CreateProperty />
        }
      ]
    },
    {
      path: '/room',
      children: [
        {
          path: 'createRoom',
          element: <CreateRoom />
        },
        {
          path: 'roomList',
          element: <RoomList />
        }
      ]
    },
    {
      path: "/company",
      children: [
        {
          path: "createCompany",
          element: <CreateCompany />
        },
        {
          path: "companyList",
          element: <CompanyList />
        }
      ]
    },
    {
      path: "/record",
      element: <RecordList />
    },
    {
      path: "/userManagement",
      element: <UserManagement />
    },
    {
      path: "/personal",
      element: <Personal />
    }
  
    // { path: "/one/two/three", exact: false, name: "三级", component: Three },
    // { path: "/about", exact: false, name: "关于", component: About, auth: [1] }
];
export default routes