import { Input, Modal } from "antd"
import { useEffect, useState } from "react"

export default function Map(params) {
    const { data, setData } = params;

    let [mapValue,setMapValue] = useState();
    let [value,setValue] = useState();
    let [inputValue,setInputValue] = useState();
    let [questionModal,setQuestionModal] = useState(false)

    const prefix = "<iframe src=";
    const nextfix = "></iframe>";

    const valueChange = (val) => {
        inputValue = val;
        setInputValue(inputValue);
        let addr =inputValue.split('"')[1];
        setData(addr);
    }

    useEffect(()=>{
        if (data) {
            inputValue = prefix+'"'+`${data}`+'"'+nextfix
            setInputValue(inputValue)
        }
    },[data])

    return <div className="Map">
        <div className="property-location">
            <div className="mapTitle">
                <h3>添加谷歌地图地址</h3>
                <div className="question" onClick={()=>setQuestionModal(true)}>?</div>
            </div>
            <div className="mapValue">
                <div className="open" onClick={()=>window.open("https://www.google.com/maps")}>打开谷歌地图</div>
                <Input value={inputValue} onChange={(e)=>valueChange(e.target.value)} placeholder="请粘贴谷歌地图HTML" />
            </div>
        </div>
        <div className="property-map">
            <h3>谷歌地图</h3>
            {
                data && <iframe
                    src={data}
                    height={400}
                    width={700}
                >
                </iframe>
            }
        </div>
        <Modal
            open={questionModal}
            onCancel={()=>setQuestionModal(false)}
            className="questModal"
            footer={false}
        >
            <div className="google">
                <img src={require('../../assets/images/img/google.png')} />
            </div>
        </Modal>
    </div>
}


