

export default function ListDataDity (params) {
    const { type, data } = params;

    const toDate = (data) => {
        let time = new Date(data);
        let Y = time.getFullYear();
        let Mon = time.getMonth() + 1;
        let Day = time.getDate();
        let H = time.getHours();
        let Min = time.getMinutes();
        if (Mon < 10) {
            Mon = '0'+Mon
        }
        if (Day < 10) {
            Day = '0'+Day
        }
        if (H < 10) {
            H = '0'+H
        }
        if (Min < 10) {
            Min = '0'+Min
        }
        return Y+'-'+Mon+'-'+Day+' '+H+':'+Min
    }

    const Dity = () => {
        let newArr = [];
        if (type === 'company') {
            data.map((e,i)=>{
                let time = toDate(e.createdAt*1000)
                let obj = {
                    key: e.id,
                    id: e.id,
                    weights: e.weight,
                    name: e.name,
                    tel: e.telephone,
                    address: e.address,
                    createTime: time,
                    estateNum: e.estateNum,
                    administrationCost: e.administrationCost,
                    fax: e.fax,
                    email: e.email,
                    guarantor: e.guarantor
                }
                newArr.push(obj)
            })
        }
        if (type=== 'property') {
            data.map((e,i)=>{
                let time = toDate(e.createdAt*1000)
                let rentStart = !e.info.rental.split(',')[0] || e.info.rental.split(',')[0] === "null" ? "" :  e.info.rental.split(',')[0];
                let rentEnd = !e.info.rental.split(',')[1] || e.info.rental.split(',')[1] === "null" ? "" :  e.info.rental.split(',')[1];
                rentStart = Number(rentStart).toLocaleString();
                rentEnd = Number(rentEnd).toLocaleString()
                let obj = {
                    key:e.id,
                    id: e.id,
                    weights: e.weight,
                    img: e.images,
                    status: e.status,
                    name: e.name,
                    address: e.info.location,
                    rooms: e.info.roomNum,
                    cost: rentStart && rentEnd ? `${rentStart}-${rentEnd}円` : "",
                    area: e.info.acreage === "null-null" ? "" : e.info.acreage,
                    createTime: time,
                    company: e.company.id,
                    companyName: e.company.name,
                    advantage: e.info.advantages
                }
                newArr.push(obj)
            })
        }
        if (type === 'room') {
            data.map((e,i)=>{
                let time = toDate(e.createdAt*1000);
                let obj = {
                    property: e.estate.id,
                    key: e.id,
                    id: e.id,
                    weights: e.weight,
                    img: `${e.images}`,
                    roomId: e.suiteNO,
                    status: e.status,
                    area: e.area,
                    createTime: time,
                    rental: e.rental.toLocaleString()
                }
                newArr.push(obj)
            })
        }
        return newArr
    }

    return (Dity())

}

