import { Button, Input, Modal, Select, Space, Table, message } from "antd";
import {SearchOutlined} from "@ant-design/icons"
import { useEffect, useState } from "react";
import CreateCompany from "../../views/Company/CreateCompany";
import CreateProperty from "../../views/Property/CreateProperty"
import CreateRoom from "../../views/Room/CreateRoom";
import '../../style/Record.scss'
import { useNavigate } from "react-router-dom";
import { updateRemark } from "../../request/api/room";

export default function DisplayList (params) {
    const { 
        type, 
        listData, 
        setListData, 
        listItem, 
        showModal, 
        setShowModal, 
        rowData,
        recordCol,
        breadCol,
        setBreadCol,
        page,
        setPage,
        pageSize,
        setPageSize,
        total
    } = params;
    const navigate = useNavigate()


    let [recordRemark,setRecordRemark] = useState()
    


    const paginationChange = ( p, pSize ) => {
        setPage(p);
        setPageSize(pSize);
    }

    const pagination = {
        current: page,
        showSizeChanger: true,
        pageSize: pageSize,
        onChange: paginationChange,
        total: total
    }



    const switchTable = () => {
        switch (type) {
            case 'property':
                return <div className="list-content">
                    <Table 
                        columns={listItem}
                        dataSource={listData}
                        // onRow={(record)=>{
                        //     return {
                                
                        //     }
                        // }}
                        pagination={pagination}
                        scroll={{ x: 1920 }}
                    />
                </div>
            case 'room':
                return <div className="list-content">
                    <Table 
                        columns={listItem}
                        dataSource={listData}
                        pagination={pagination}
                        scroll={{ x: 1920 }}
                    />
                </div>
            case 'company':
                return <div className="list-content">
                    <Table 
                        columns={listItem}
                        dataSource={listData}
                        pagination={pagination}
                        scroll={{ x: 1920 }}
                    />
                </div>
            case 'record':
                return <div className="list-content">
                    <Table 
                        columns={recordCol}
                        dataSource={listData}
                        className="recordTable"
                        pagination={pagination}
                        scroll={{ x: 1920 }}
                    />
                </div>
            default:
                break;
        }
    }

    const switchModal = () => {
        switch (type) {
            case 'company':
                return <CreateCompany 
                    edit={true} 
                    rowData={rowData}
                />
            case 'property':
                console.log(type);
                return <CreateProperty 
                    edit={true}
                    rowData={rowData}
                />
            case 'room':
                return <CreateRoom 
                    edit={true}
                    rowData={rowData}
                />
            case 'record':
                return <div className="recordModal">
                    <p className="title">备注</p>
                    <div className="content">
                        <Input.TextArea value={recordRemark} onChange={(e)=>remarkChange(e.target.value)} />
                    </div>
                    <div className="btn">
                        <Button style={{marginRight: 10}} onClick={()=>setShowModal(false)}>取消</Button>
                        <Button type="primary" onClick={()=>editRemark()}>确定</Button>
                    </div>
                </div>
            default:
                break;
        }
    }

    const remarkChange = (val) => {
        recordRemark = val;
        setRecordRemark(recordRemark)
    }

    useEffect(()=>{
        console.log("remark===",recordRemark);
    },[recordRemark])

    const editRemark = async () => {
        let obj = {
            id: rowData.id,
            remark: recordRemark,
            name: rowData.name,
            telephone: rowData.telephone,
            email: rowData.email,
            estateID: rowData.estate.id,
            suite: rowData.suite,
            time: rowData.time,
            description: rowData.description,
        }
        await updateRemark(obj)
        .then((res) => {
            if (res.code === 0) {
                message.success(res.msg)
                setTimeout(()=>{
                    navigate(0)
                },500)
            }else{
                message.error(res.msg)
            }
        }).catch((err) => {
            
        });
    }

    useEffect(()=>{
        if (rowData) {
            console.log("rowData====",rowData);
            recordRemark = rowData.remark;
            setRecordRemark(recordRemark)
        }
    },[rowData])
 


    return <div className="DisplayList">
        {switchTable()}
        <Modal
            open={showModal}
            onCancel={()=>setShowModal(false)}
            footer={false}
            className={`${type === 'company' && 'CompanyModal'}
                ${type === 'property' && 'propertyModal'}
                ${type === 'room' && 'roomModal'}
            `}
        >
            {/* <CreateCompany 
                edit={true} 
                rowData={rowData}
            /> */}
            {switchModal()}
        </Modal>
    </div>
}


