import { PlusOutlined } from '@ant-design/icons';
import {  message, Upload } from 'antd';
import { useEffect, useState } from 'react';
import CropUpload from './CropUpload'


export default function UploadImg (params) {

    const { 
      type, 
      setHash, 
      hash, 
      edit, 
      imgList, 
      setImgList, 
      step, 
      imageDesc, 
      setImageDesc, 
      uploadImage,
      uidArr,
      hashList,
      setHashList
    } = params


    let [cropperImg,setCropperImg] = useState();
    let [cropImgName,setCropImgName] = useState();
    // 选择裁剪的图片id
    let [cropImageId,setCropImageId] = useState()
    
    let [concatNum,setConcatNum] = useState(0)
    let [isEditImages,setIsEditImages] = useState(false)

    const props = {
        name: 'file',
        method: 'post',
        action: `${process.env.REACT_APP_API_URL}/file/upload`,
        headers: {
            'x-token': localStorage.getItem('btl-token')
        },
        onChange(info) {
          const { status } = info.file;
          if (status === "done") {
              let url = `${info.file.response.data.url}`;
              setHash(url);
              message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === "error") {
              message.error(`${info.file.name} file upload failed.`);
          }
          setImgList([...info.fileList])
        },
        onSuccess(res, file) {
          let imgArr = imgList
          if (res.code === 0) {
              message.success(`${file.name}上传成功`)
              if (type === "property" || type === 'room' || type === 'room-type') {
                let arr = hashList;
                arr.push(res.data.url);
                setHashList([...arr])
                if (edit) {
                  setIsEditImages(true)
                }
              }else{
                setHash(res.data.url)
              }
              imgArr.map((e)=>{
                  if (file.uid === e.uid) {
                      e.status = "done"
                  }
              })
          }
          setImgList([...imgArr])
        },
        listType:"picture-card",
        multiple: type === 'property' || type === 'room' ? true : false,
        maxCount: (type === 'company' || type === 'room-type') ? 1 : false
      };


      const beforeUpload = (file) => {
          if (file.type === 'image/jpeg' || file.type === 'image/png') {
            const formatArr = ["image/jpeg","image/png"]
            let isImage = false
            formatArr.map((e)=>{
                if ( file.type === e ) {
                  isImage = true
                }
            })
            if (!isImage) {
                message.error("You can only upload JPG/PNG file!");
                return Upload.LIST_IGNORE
            }
          }
      }


      function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
      
        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }
      
        return new Blob([arrayBuffer], { type: mimeString });
      }
      
      function convertBase64ToFile(base64Data, fileName) {
        const blob = dataURItoBlob(base64Data);
        return new File([blob], fileName, { type: blob.type });
      }

      

      // const handlePreview = async (file) => {
      //     console.log();
      //     if (!file.url && !file.preview) {
      //       file.preview = await getBase64(file.originFileObj);
      //     }
      //     console.log("previewFile----",file); 
      //     // const reader = new FileReader();
      //     // const blob = new Blob([file],{type: file.type})
      //     // reader.readAsDataURL(blob);
      //     // console.log("blob====",blob);
      //     previewImg = file.preview;
      //     cropImgName = file.name;
      //     setPreviewImg(previewImg);
      //     setCropImgName(cropImgName);
      //     setCropModal(true);

      // }


      const uploadButton = (
        <div>
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            <p className='up-title'>上传文件</p>
            <p className='subtitle'>支持jpg、png文件格式</p>
          </div>
        </div>
      );

      const removeFile = (file) => {
        let arr = imgList.filter((ele)=>ele.uid !== file.uid);
        let hashArr = []
        arr.map((e)=>{
          let strArr = e.url.split("/")
          hashArr.push(strArr[3]+"/"+strArr[4]+"/"+strArr[5])
        })
        setImgList([...arr])
        setHashList([...hashArr])
      }

      useEffect(()=>{
        let arr = [];
        if ((hash && edit) || (type === "personal" && hash)) {
          if ((type=== 'property' || type === "room") && step !== "surroundings") {
            let urlArr = hash.split(",")
            if (!isEditImages) {
              console.log(13);
              setHashList([...urlArr])
            }
            urlArr.map((e,i)=>{
                arr.push({
                  status: 'done',
                  url: `${process.env.REACT_APP_API_URL}/${e}`,
                  uid: i + 1
                })
            })
            setImgList([...arr])
          }else if (step) {
            let descObj = {}
            let urlArr = JSON.parse(hash);
            urlArr.map((e,i)=>{
              arr.push({
                status: 'done',
                url: `${process.env.REACT_APP_API_URL}/${e.image}`,
                uid: i + 1
              })
              descObj[i+1] = e.description
            })
            setImageDesc({...descObj})
            setImgList([...arr])
          }else {
            arr.push({
              status: 'done',
              url: `${process.env.REACT_APP_API_URL}/${hash}`
            })
            setImgList([...arr])
          }
          // imgList = arr;
        }
      },[hash])

      useEffect(()=>{
          let str = ''
          if (step) {
            let arr = []
            if (hash && setConcatNum === 0) {
              arr = JSON.parse(hash);
              concatNum += 1;
              setConcatNum(concatNum)
            }
            uidArr?.map((e,i)=>{
              arr.push({
                image: e.url,
                description: imageDesc[e.uid] ? imageDesc[e.uid] : ""
              })
              str = JSON.stringify(arr)
            })
            setHash(str)
          }else{
            if (hashList?.length > 0) {
                hashList.map((e)=>{
                    if (str) {
                        str = `${str},${e}`
                    }else {
                        str = `${e}`
                    }
                })
                setHash(str)
                console.log(4);
            }
          }
      },[hashList])


      useEffect(()=>{
        if (cropperImg) {
            let n = convertBase64ToFile(cropperImg,cropImgName);
            n.thumbUrl = cropperImg
            n.uid = cropImageId
            let index
            imgList.forEach((e,i)=>{
              if (e.uid === n.uid) {
                index = i
              }
            })
            let newArr = imgList;
            newArr.splice(index,1,n)
            setImgList([...newArr])
        }
      },[cropperImg])


    return <div className="UploadImg">
        <div className='cropList'>
          <div>

          </div>
        </div>
        {
          type === "personal" ? 
          <Upload 
            {...props}
            beforeUpload={beforeUpload}
            fileList={imgList}
            // onPreview={handlePreview}
          >
              {/* {
                  type === 'property' ? uploadButton : <Button icon={<UploadOutlined />}>Click to Upload</Button>
              } */}
              {uploadButton}
          </Upload>
          :
          type === "property" || type === 'room' ?
          <CropUpload 
            fileList={imgList}
            setFileList={setImgList}
            uploadButton={uploadButton}
            cropImg={cropperImg}
            setCropImg={setCropperImg}
            cropImgName={cropImgName}
            setCropImgName={setCropImgName}
            uploadImage={uploadImage}
            cropImageId={cropImageId}
            setCropImageId={setCropImageId}
            edit
            step={step}
            imageDesc={imageDesc}
            setImageDesc={setImageDesc}
          />
          :
          <Upload 
            {...props}
            beforeUpload={beforeUpload}
            fileList={imgList}
            // onPreview={handlePreview}
            onRemove={(file)=>removeFile(file)}
          >
              {/* {
                  type === 'property' ? uploadButton : <Button icon={<UploadOutlined />}>Click to Upload</Button>
              } */}
              {uploadButton}
          </Upload>
        }
    </div>
}

