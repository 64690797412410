import { useEffect, useState } from "react";

export default function PropertyInfoDity (params) {
    const { basicData,
        basic,
        mapData,
        checkCompany,
        propertyImg,
        surroundings,
        html,
        advantage,
        advantageList,
        propertyWeight,
        iconList,
        detailInfo
    } = params

    const infoDity = () => {
        let advantageStr = ""
        advantageList.map((e)=>{
            if (advantageStr) {
                advantageStr = advantageStr + "," + e
            }else{
                advantageStr = advantageStr + e
            }
        })
        let equipStatus = ""
        iconList.map((e)=>{
            if (e.isSelected) {
                if (equipStatus) {
                    equipStatus = equipStatus + ',' + '1'
                }else{
                    equipStatus = equipStatus + '1'
                }
            }else {
                if (equipStatus) {
                    equipStatus = equipStatus + ',' + '0'
                }else{
                    equipStatus = equipStatus + '0'
                }
            }
        })
        let infoObj = {
            name: basicData.name,
            description: basicData.desc,
            images: propertyImg,
            weight: propertyWeight,
            isPubilsh: false,
            decorating: false,
            companyID: checkCompany,
            info: {
                location: basic.address,
                map: mapData,
                navigation: basic.access,
                rental:  basic.rentStart && basic.rentEnd ? `${basic.rentStart},${basic.rentEnd}` : "",
                acreage: basic.areaStart && basic.areaEnd ? `${basic.areaStart}-${basic.areaEnd}` : "",
                roomNum: basic.rooms,
                tenementData: html,
                surroundings: surroundings,
                advantages: advantageStr,
                equipments: detailInfo.equipment,
                contractPeriod: detailInfo.contract,
                currentStatus: detailInfo.statusQuo,
                extradition: detailInfo.extradition,
                equipStatus: equipStatus
            }
        }
        return infoObj
    }
    return (infoDity())
}

