import { Button, Select, Table, Space, Modal, Cascader, message } from "antd"
import '../../style/UserManagement.scss'
import CreateUserModal from "../../components/UserManagement/CreateUserModal"
import { useEffect, useState } from "react"
import { getUserList, deleteUser } from "../../request/api/user"
import { useNavigate } from "react-router-dom"

export default function UserManagement () {
    const navigate = useNavigate();
    const {confirm} = Modal

    const [modalOpen,setModalOpen] = useState(false)
    let [userList,setUserList] = useState([])
    let [rowData,setRowData] = useState({})
    let [allData,setAllData] = useState([])
    let [edit,setEdit] = useState(false)

    let [page,setPage] = useState(1)
    let [pageSize,setPageSize] = useState(10)
    let [total,setTotal] = useState();

    // const options = [
    //     {
    //         label: "成员管理",
    //         value: 'property'
    //     }
    // ]

    const permissionList = [
        {
            label: '全部权限',
            value: 0,
            children: [
                {
                    label: '物业管理',
                    value: 1,
                },
                {
                    label: '房间管理',
                    value: 2,
                },
                {
                    label: '公司管理',
                    value: 3
                },
                {
                    label: '预约记录',
                    value: 4
                },
                {
                    label: '成员管理',
                    value: 5
                }
            ]
        }
    ]

    const checkRole = (data) => {
        let arr = []
        if (data.length === 0 || data.length === 5) {
            arr.push(0)
        }else{
            data.map((e)=>{
                arr.push([0,e])
            })
        }
        return arr
    }


    const getList = async () => {
        await getUserList({
            page,
            pageSize
        })
        .then((res) => {
            console.log("userRes===",res);
            if (res.code === 0 && res.data.list) {
                console.log(1);
                let arr = [];
                allData = res.data.list;
                setAllData([...allData])
                total = res.data.total
                setTotal(total)
                res.data.list.map((e,i)=>{
                    let obj = {
                        key: e.id,
                        img: e.avatar,
                        name: e.nickname,
                        account: e.username,
                        role: e.authority.authorityName,
                        permission: checkRole(e.authoritySourceIds)
                    }
                    arr.push(obj)
                })
                console.log("arr-===",arr);
                userList = arr;
                setUserList([...userList])
                console.log("list===",userList);
            }
        }).catch((err) => {
            
        });
    }

    const editModal = (data) => {
        console.log(data);
        // allData.map((e,i)=>{
        //     console.log("all-----",e);
        // })
        rowData = data;
        setRowData({...rowData})
        setEdit(true)
        setModalOpen(true)
    }

    const paginationChange = ( p, pSize ) => {
        setPage(p);
        setPageSize(pSize);
    }

    const pagination = {
        current: page,
        showSizeChanger: true,
        pageSize: pageSize,
        onChange: paginationChange,
        total: total
    }

    useEffect(()=>{
        getList()
    },[page,pageSize])

    const userCol = [
        {
            title: "成员名称",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "账号",
            dataIndex: "account",
            key: "account"
        },
        {
            title: "角色",
            dataIndex: "role",
            key: "role"
        },
        {
            title: "权限范围",
            dataIndex: "permission",
            key: "permission",
            render: (_,record) => (
                <Cascader 
                    options={permissionList}
                    value={record.permission}
                    style={{width:'50%', color: "rgba(0, 0, 0, 0.88)"}}
                    disabled
                    bordered={false}
                    multiple
                    maxTagCount={5}
                />
            )
        },
        {
            title: "操作",
            dataIndex: "action",
            key: "action",
            render: (_,record) => (
                <Space>
                    <a onClick={()=>editModal(record)}>编辑</a>
                    {/* <a>移除</a> */}
                    <a onClick={()=>delModal(record.key)}>移除</a>
                </Space>
            )
        }
    ]

    const delModal = (id) => {
        confirm({
            title: "是否需要删除这个用户信息？",
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk(){
                delCompany(id)
            }
        })
    }

    const delCompany = async (id) => {
        await deleteUser({id:id})
        .then((res)=>{
            if (res.code === 0) {
                message.success(res.msg)
                setTimeout(()=>{
                    navigate(0)
                },1000)
            }else{
                message.error("删除失败")
            }
        })
    }

    const celModal = () => {
        setRowData({})
        setModalOpen(false)
    }

    const addModal = () => {
        setEdit(false)
        setModalOpen(true)
    }



    return <div className="UserManagement">
        <div className="management-title">
            <div className="title">
                <h3>成员管理</h3>
            </div>
            <Button type="primary" onClick={()=>addModal()}>添加成员</Button>
        </div>
        <div className="management-content">
            <Table 
                columns={userCol}
                dataSource={userList}
                pagination={pagination}
            />
        </div>
        <Modal
            className="create-modal" 
            open={modalOpen}
            onCancel={()=>celModal()}
            footer={false}
            destroyOnClose
        >
            <CreateUserModal  rowData={rowData} setModalOpen={setModalOpen} edit={edit} />
        </Modal>
    </div>
} 


