import '../../style/Property.scss'
import { Input, Steps, Button,Segmented, Select, message, Spin, Modal, Result } from 'antd'
import UploadImg from '../../components/Upload/UploadImg'
import { useEffect, useState } from 'react'
import BasicData from '../../components/Property/BasicData'
import Map from '../../components/Property/Map'
import Surroundings from '../../components/Property/Surroundings'
import Equipment from '../../components/Property/Equipment'
import Detailed from '../../components/Property/Detailed'
import { createPropertyInfo, editPropertyInfo } from '../../request/api/property'
import { getAllCompany } from '../../request/api/company'
import PropertyInfoDity from '../../utils/PropertyInfoDity'
import { uploadImg } from '../../request/api/property';
import { useNavigate } from 'react-router-dom'
import EquipmentAndDetailed from '../../components/Property/EquipmentAndDetailed'

export default function CreateProperty (params) {
    const { edit, rowData } = params;

    const navigate = useNavigate()

    let [current,setCurrent] = useState(0)
    let [segmentValue,setSegmentValue] = useState('基本情报');
    // 第一步数据
    let [basicData,setBasicData] = useState({
        name: "",
        desc: "",
    })
    // 基本情报数据
    let [basic,setBasic] = useState({
        address: "",
        access: "",
        rentStart: '',
        rentEnd: '',
        areaStart:'',
        areaEnd: '',
        rooms: "",
        tenant: ""
    })

    // 地图数据
    let [mapData,setMapData] = useState()

    // 周边环境
    let [surroundings,setSurroundings] = useState()
    let [imageDesc,setImageDesc] = useState({})
    // 上传后的图片url和对应描述
    let [surroundingsInfo,setSurroundingsInfo] = useState([])

    // 公司列表
    let [companyList,setCompanyList] = useState([])
    // 选择公司
    let [checkCompany,setCheckCompany] = useState(1)
    // 物业图片
    let [propertyImg,setPropertyImg] = useState()
    // 物业名称输入框状态
    let [nameStatus,setNameStatus] = useState()
    // 描述输入框状态
    let [descStatus,setDescStatus] = useState() 
    // 富文本框内容
    let [html,setHtml] = useState("")
    // 图片列表
    let [imgList,setImgList] = useState([])
    // 周边环境图片列表
    let [surroundingsImgList,setSurroundingsImgList] = useState([])
    // 物业优势
    let [advantage,setAdvantage] = useState({
        recontractBusiness: false,
        Deposit: false,
        giftMoney: false,
        BrokerageFee: false,
        guarantee: false,
        pet: false,
        WomenOnlyArea: false
    })
    // 周边环境图片id顺序
    let [uidArr,setUidArr] = useState([])
    let [hashList,setHashList] = useState([])
    // 物业图片上传成功参数
    let [propertyImageSuccess,setPropertyImageSuccess] = useState(false)
    // 周边环境图片上传成功参数
    let [surroundingsImageSuccess,setSurroundingsImageSuccess] = useState(false)
    // 周边环境不更改
    let [surroundingsNoEdit,setSurroundingsNoEdit] = useState(false)
    // 上传弹框显示
    let [uploadModal,setUploadModal] = useState(false)
    // 新建物业状态
    let [createState,setCreateState] = useState("loading");
    // 物业优势
    let [advantageList,setAdvantageList] = useState([]);
    // 物业权重
    let [propertyWeight,setPropertyWeight] = useState(1)

    let [iconList,setIconList] = useState([
        {
            name: '新改装',
            icon: 'newlyRenovated.png',
            activeIcon: 'NewlyRenovatedActive.png',
            isSelected: false
        },
        {
            name: '2階以上の物件',
            icon: 'highFloor.png',
            activeIcon: 'highFloorActive.png',
            isSelected: false
        },
        {
            name: '南向き',
            icon: 'facingSouth.png',
            activeIcon: 'facingSouthActive.png',
            isSelected: false
        },
        {
            name: '駐車場あり',
            icon: 'parkingLot.png',
            activeIcon: 'parkingLotActive.png',
            isSelected: false
        },
        {
            name: 'オートロック',
            icon: 'autoLock.png',
            activeIcon: 'autoLockActive.png',
            isSelected: false
        },
        {
            name: '冷蔵庫',
            icon: 'refrigerator.png',
            activeIcon: 'refrigeratorActive.png',
            isSelected: false
        },
        {
            name: '電子レンジ',
            icon: 'MicroWaveOven.png',
            activeIcon: 'MicroWaveOvenActive.png',
            isSelected: false
        },
        {
            name: 'エアコン',
            icon: 'airConditioner.png',
            activeIcon: 'airConditionerActive.png',
            isSelected: false
        },
        {
            name: 'バス・トイレ別',
            icon: 'SeparateBathroomAndToilet.png',
            activeIcon: 'SeparateBathroomAndToiletActive.png',
            isSelected: false
        },
        {
            name: '高速インターネット',
            icon: 'TrackingFunction.png',
            activeIcon: 'TrackingFunctionActive.png',
            isSelected: false
        },
        {
            name: 'フローリング',
            icon: 'woodenFloor.png',
            activeIcon: 'woodenFloorActive.png',
            isSelected: false
        },
        {
            name: 'ペット相談可',
            icon: 'petConsultation.png',
            activeIcon: 'petConsultationActive.png',
            isSelected: false
        },
        {
            name: '机椅子',
            icon: 'tablesAndChairs.png',
            activeIcon: 'tablesAndChairsActive.png',
            isSelected: false
        },
        {
            name: '洗濯機',
            icon: 'washingMachine.png',
            activeIcon: 'washingMachineActive.png',
            isSelected: false
        },
    ])
    let [detailInfo,setDetailInfo] = useState({
        equipment: '',
        contract: '',
        statusQuo: '',
        extradition: ''
    })


    const stepsItem = [
        {
            title: '物业信息'
        },
        {
            title: "基本情报"
        },
        {
            title: "VR看房"
        }
    ]

    const segmentOptions = [
        '基本情报',
        // '地図',
        '周边环境',
        // '设备',
        // '详细',
        '設備·详情'
    ]

    const next = () => {
        setCurrent(current + 1);
    }

    const prev = () => {
        setCurrent(current - 1);
    }

    const switchContent = () => {
        switch (segmentValue) {
            case '基本情报':
                return <BasicData 
                    data={basic} 
                    edit={edit} 
                    setData={setBasic} 
                    mapData={mapData} 
                    setMapData={setMapData} 
                    name={basicData.name} 
                    html={html}
                    setHtml={setHtml}
                />
            // case '地図':
            //     return <Map data={mapData} setData={setMapData} />
            case '周边环境':
                return <Surroundings 
                    setData={setSurroundings} 
                    data={surroundings} 
                    edit={edit} 
                    imgList={surroundingsImgList}
                    setImgList={setSurroundingsImgList}
                    imageDesc={imageDesc}
                    setImageDesc={setImageDesc}
                    surroundingsInfo={surroundingsInfo}
                    setSurroundingsInfo={setSurroundingsInfo}
                    setHashList={setHashList}
                    hashList={hashList}
                    uidArr={uidArr}
                    setUidArr={setUidArr}
                />
            case '設備·详情':
                return <EquipmentAndDetailed iconList={iconList} setIconList={setIconList} detailInfo={detailInfo} setDetailInfo={setDetailInfo} />
            default:
                break;
        }
    }

    const stepChange = (val) => {
        setCurrent(val)
    }

    const getCompany = async () => {
        await getAllCompany()
        .then((res) => {
            if (res.code === 0 && res.data.list) {
                let arr = []
                res.data.list.map((e)=>{
                    arr.push({
                        value: e.id,
                        label: e.name
                    })
                })
                companyList = arr;
                setCompanyList([...companyList])
            }
        }).catch((err) => {
            
        });
    }

    // 选择公司操作
    const changeCompany = (value) => {
        checkCompany = value;
        setCheckCompany(checkCompany);
    }

    const nameChange = (val) => {
        basicData.name = val;
        setBasicData({...basicData})
    }

    const descChange = (val) => {
        basicData.desc = val;
        setBasicData({...basicData})
    }

    // 创建
    const createOperate = async () => {
        const obj = {
            basicData,
            basic,
            mapData,
            checkCompany,
            propertyImg,
            surroundings,
            html,
            advantageList,
            advantage,
            propertyWeight,
            iconList,
            detailInfo
        }
        const infoData = PropertyInfoDity(obj)
        if ( !infoData.name) {
            message.error("物业名称不能为空")
            setNameStatus("error")
            setCreateState("error")
            return false
        }
        else if (!infoData.description ) {
            message.error("物业描述不能为空")
            setCreateState("error")
            setDescStatus("error")
            return false
        }else if (!infoData.images) {
            setCreateState("error")
            message.error("物业图片不能为空")
            return false
        }else if (basic.areaStart < 0 || basic.areaEnd < 0) {
            setCreateState("error")
            message.error("面积数值不能为负")
            return false
        }else if (basic.rentStart < 0 || basic.rentEnd < 0) {
            setCreateState("error")
            message.error("租金数值不能为负")
            return false
        }else if ((basic.rentStart && !basic.rentEnd) || (!basic.rentStart && basic.rentEnd)) {
            setCreateState("error")
            message.error("租金范围请填写完整")
            return false
        }else if ((basic.areaStart && !basic.areaEnd) || (!basic.areaStart && basic.areaEnd)) {
            setCreateState("error")
            message.error("面积范围请填写完整")
            return false
        }
        else{
            await createPropertyInfo(infoData)
            .then((res) => {
                if (res.code===0) {
                    // message.success(res.msg);
                    setCreateState("success")
                    setTimeout(()=>{
                        navigate('/property/propertyList')
                    },1000)
                }else{
                    // message.error(res.msg)
                    setCreateState("error")
                }
            }).catch((err) => {
                
            });
        }
    }

    // 上传图片
    const uploadImageAsync = async () => {
        setUploadModal(true)
        setCreateState("loading")
        await uploadImage("")
        await uploadImage("surroundings")
    }

    // 修改
    const updateOperate = async () => {
        const obj = {
            basicData,
            basic,
            mapData,
            checkCompany,
            propertyImg,
            surroundings,
            html,
            advantage,
            advantageList,
            propertyWeight,
            iconList,
            detailInfo
        }
        const infoData = PropertyInfoDity(obj);
        infoData.id = rowData.id;
        await editPropertyInfo(infoData)
        .then((res) => {
            if (res.code === 0){
                setCreateState("success")
                setTimeout(()=>{
                    navigate(0)
                },3000)
            }else{
                setCreateState("error")
            }
        }).catch((err) => {
            
        });
    }

    const uploadImage = async (step) => {
        const formData = new FormData();
        let uidArr = [];
        let uidAndUrl = []
        if (!step) {
            imgList.forEach((file)=>{
                uidArr.push(file.uid)
                if (file.originFileObj) {
                  formData.append("file",file.originFileObj)
                }else{
                  formData.append("file",file)
                }
            })
        }else if (step === "surroundings") {
            surroundingsImgList.forEach((file)=>{
                uidArr.push(file.uid)
                if (file.originFileObj) {
                  formData.append("file",file.originFileObj)
                }else{
                  formData.append("file",file)
                }
            })
        }
          await uploadImg(formData)
          .then((res) => {
            if (res.code === 0) {
                if (step==="surroundings" && edit) {
                  surroundingsImgList.map((e)=>{
                    if (e.status === 'done') {
                      let nArr = e.url.split('/')
                      let str = `${nArr[3]}/${nArr[4]}/${nArr[5]}`
                      uidAndUrl.push({
                        uid: e.uid,
                        url: str
                      })
                    }
                  })
                }

                let arr = []
                imgList.map((e)=>{
                  if (e.url) {
                    let nArr = e.url.split('/')
                    let str = `${nArr[3]}/${nArr[4]}/${nArr[5]}`
                    arr.push(str)
                  }
                });
                if (res.data) {
                  let length = uidAndUrl.length
                  res.data.map((e,i)=>{
                    uidAndUrl.push({
                      uid: edit ? uidArr[length + i] : uidArr[i],
                      url: e
                    })
                  })
                  setUidArr([...uidAndUrl])
                  let newArr = arr.concat(res.data)
                  setHashList([...newArr])
                  if (step === "surroundings") {
                    editHash(step,uidAndUrl,"upload")
                  }else{
                      editHash(step,newArr,"upload")
                  }
                }else{
                  setUidArr([...uidAndUrl])
                  setHashList([...arr])
                  if (step === "surroundings") {
                    editHash(step,uidAndUrl,"noUpload")
                  }else{
                      editHash(step,arr,"noUpload")
                  }
                }
                if (step === "surroundings") {
                    setSurroundingsImageSuccess(true)
                }
                if (!step) {
                    setPropertyImageSuccess(true)
                }
            }else{
                setCreateState("error")
            }
          }).catch((err) => {
            
          });
      }

    const editHash = (step,arr,isUpload) => {
        let str = ''
        if (step) {
            let surArr = []
            if (arr.length > 0) {
                arr.map((e)=>{
                    surArr.push({
                        image: e.url,
                        description: imageDesc[e.uid] ? imageDesc[e.uid] : ""
                      })
                      str = JSON.stringify(surArr)
                })
              }
            //   if (surroundings === str) {
            //     setSurroundingsNoEdit(true)
            //   }
              surroundings = str;
              setSurroundings(surroundings)
        }else{
          if (arr.length > 0) {
            arr.map((e)=>{
                if (str) {
                    str = `${str},${e}`
                }else {
                    str = `${e}`
                }
            })
            propertyImg = str
            setPropertyImg(propertyImg)
          }
        }
    }

    useEffect(()=>{
        if (rowData) {
            console.log(rowData);
            if (rowData.info.advantages) {
                let arr = rowData.info.advantages.split(",");
                setAdvantageList([...arr])
            }
            let str = rowData.info.equipStatus
            if (str) {
                let arr = str.split(',')
                let list = iconList
                arr.map((e,i)=>{
                    if (e === '1') {
                        list[i].isSelected = true
                    }
                })
                setIconList([...list])
            }
            basicData.name = rowData.name;
            basicData.desc = rowData.description;
            basic.address = rowData.info.location;
            basic.access = rowData.info.navigation;
            basic.rentStart = rowData.info.rental.split(',')[0] === "null" ? "" : rowData.info.rental.split(',')[0];
            basic.rentEnd = rowData.info.rental.split(',')[1] === "null" ? "" : rowData.info.rental.split(',')[1];
            basic.areaStart = rowData.info.acreage.split('-')[0] === "null" ? "" : rowData.info.acreage.split('-')[0];
            basic.areaEnd = rowData.info.acreage.split('-')[1] === "null" ? "" : rowData.info.acreage.split('-')[1];
            basic.rooms = rowData.info.roomNum;
            html = rowData.info.tenementData;
            mapData = rowData.info.map;
            surroundings = rowData.info.surroundings;
            checkCompany = rowData.company.id;
            propertyImg = rowData.images;
            propertyWeight = rowData.weight;
            detailInfo.equipment = rowData.info.equipments;
            detailInfo.contract = rowData.info.contractPeriod;
            detailInfo.extradition = rowData.info.extradition;
            detailInfo.statusQuo = rowData.info.currentStatus;
            setBasic({...basic});
            setBasicData({...basicData});
            setMapData(mapData);
            setCheckCompany(checkCompany);
            setPropertyImg(propertyImg);
            setSurroundings(surroundings);
            setHtml(html)
            setPropertyWeight(propertyWeight)
        }
    },[rowData])


    useEffect(()=>{
        if (propertyImageSuccess && surroundingsImageSuccess ) {
            setTimeout(()=>{
                if (edit) {
                    updateOperate()
                }else{
                    createOperate()
                }
            },1000)
        }
    },[propertyImageSuccess,surroundingsImageSuccess])

    useEffect(()=>{
        getCompany()
    },[])

    const advantageChange = (e) => {
        setAdvantageList([...e])
    }

    return <div className="CreateProperty">
        <h1 className='title'>{
            edit ? '修改物业' : '新建物业'
        }</h1>
        <div className='content'>
            <div className='steps'>
                <Steps 
                    current={current}
                    items={stepsItem}
                    onChange={(val)=>stepChange(val)}
                />
            </div>
            {
                current === 0 ? <div className='create-content'>
                    <div className='create-item'>
                        <p className='create-title'>选择管理公司</p>
                        <Select 
                            defaultValue={0}
                            value={checkCompany}
                            style={{
                              width: 120,
                            }}
                            options={companyList}
                            onChange={changeCompany}
                        />
                    </div>
                    <div className='create-item'>
                        <p className='create-title'>物业名称</p>
                        <Input 
                            value={basicData.name} 
                            onChange={(e)=>nameChange(e.target.value)} 
                            status={nameStatus} 
                            onFocus={()=>setNameStatus('')}
                            maxLength={50}
                            showCount
                        />
                    </div>
                    <div className='create-item'>
                        <p className='create-title'>物业优势</p>
                        <Select 
                            mode="tags"
                            onChange={advantageChange}
                            style={{width: '100%'}}
                            value={advantageList}
                        />
                    </div>
                    <div className='create-item'>
                        <p className='create-title'>物业概要</p>
                        <Input.TextArea 
                            value={basicData.desc} 
                            onChange={(e)=>descChange(e.target.value)} 
                            status={descStatus} 
                            onFocus={()=>setDescStatus('')} 
                            autoSize={{minRows: 2}}
                            maxLength={500}
                            showCount
                        />
                    </div>
                    <div className='create-item'>
                        <p className='create-title'>物业图片</p>
                        <UploadImg 
                            type='property' 
                            setHash={setPropertyImg} 
                            hash={propertyImg} 
                            edit={edit} 
                            imgList={imgList} 
                            setImgList={setImgList} 
                            uploadImage={uploadImage}
                            setHashList={setHashList}
                            hashList={hashList}
                        />
                    </div>
                </div>
                :
                current === 1 ? 
                <div className='create-content2'>
                    <Segmented options={segmentOptions} value={segmentValue} onChange={(value)=>setSegmentValue(value)} />
                    {switchContent()}
                </div> 
                : 
                <div>第三步</div>
            }
        </div>
        <div className='create-footer'>
            {
                !edit ? <Button>取消</Button> : '' 
            }
            {
                current < 2 ? 
                (
                    <div className='steps-btn'>
                        {
                            current === 0 ? '' : <Button type='primary' onClick={()=>prev()}>上一步</Button>
                        }
                        <Button type='primary' onClick={()=>next()}>下一步</Button> 
                        {
                            edit ? <Button type='primary' onClick={() =>uploadImageAsync()}>修改</Button>
                            :
                            <Button type='primary' onClick={() =>uploadImageAsync()}>创建</Button>
                        }
                    </div>
                )
                :
                (
                    <div className='steps-btn'>
                        <Button type='primary' onClick={()=>prev()}>上一步</Button>
                        {
                            edit ? <Button type='primary' onClick={() =>uploadImageAsync()}>修改</Button>
                            :
                            <Button type='primary' onClick={() =>uploadImageAsync()}>创建</Button>
                        }
                    </div>
                )
            }
        </div>
        <Modal
            open={uploadModal}
            closeIcon={false}
            maskClosable={false}
            footer={false}
            className='loading-modal'
        >
            {
                createState === "loading" && <div className='uploading'>
                    <Spin />
                    <div className='loading-text'>
                        {
                            edit ? <p>正在修改，请稍等...</p> : <p>正在创建，请稍等...</p>
                        }
                    </div>
                </div>
            }
            {
                createState === "success" && <Result
                    status="success"
                    title="操作成功，即将跳转..."
                />
            }
            {
                createState === "error" && <Result
                    status="error"
                    title="操作失败"
                    extra={[
                        <Button type="primary" key="console" onClick={()=>setUploadModal(false)}>
                            关闭
                        </Button>
                    ]}
                />
            }
        </Modal>
    </div>
}

