import { Layout, Button, Checkbox, Form, Input, Divider, message } from "antd";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useState, useEffect } from "react";
import "@/assets/styles/view-style/login.scss";
import { useNavigate } from "react-router-dom";
import { resParse } from "../../utils/pares";
import { login } from "../../request/api/user";

export default function Login(params) {
    const navigateTo = useNavigate()


    let [captchaData, setCapatchaData] = useState(4638);


    // const onFinish = (values) => {
    //     console.log('Received values of form: ', values);

    //     //  
    //     localStorage.setItem("lege-react-management-token","xxxx");
    //     navigateTo("/")
    // };

    const onFinish = (values) => {
        goHome({...values, CaptchaId: captchaData.captchaId});
    };

    const goHome = (values) => {
        login(values)
        .then(res => {
            if (resParse(res)) {
                message.success(res.msg);
                localStorage.setItem("btl-token",res.data.token);
                localStorage.setItem("btl-user",JSON.stringify(res.data.user))
                localStorage.setItem("authorityId",JSON.stringify(res.data.user.authoritySourceIds))
                setTimeout(() => {
                    navigateTo("/",{replace: true})
                }, 1000);
            }else {
                // getcaptcha()
            }
        })
    }

    useEffect(() => {

    },[])
    
    return (
        <Layout className="login">
            <div className="model">
                <h3>后台管理系统</h3>
                <Divider />
                <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                >
                <Form.Item
                    name="username"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your Username!',
                    },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your Password!',
                    },
                    ]}
                >
                    <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                    Log in
                    </Button>
                </Form.Item>
                </Form>
            </div>
        </Layout>
    );
}