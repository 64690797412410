import {
    HomeOutlined,
    BankOutlined,
    FormOutlined,
    UnorderedListOutlined,
    TeamOutlined
  } from "@ant-design/icons";


// export const menus = [
//     {
//       key: "/",
//       label: "首页",
//       icon: <HomeOutlined />,
//       auth: 0
//     },
//     {
//       key: ""
//     },
//     {
//         key: "/others",
//         label: "Others",
//         icon: <PieChartOutlined />,
//         auth: 0,
//         children: [
//             {
//                 key: "/others/404",
//                 label: "404"
//             },
//             {
//                 key: "/others/505",
//                 label: "505"
//             }
//         ]
//     },
//     // {
//     //   title: "公告",
//     //   key: "/announcing",
//     //   icon: <SoundOutlined />,
//     //   auth: 1,
//     //   subs: [
//     //     {
//     //       title: "新建公告",
//     //       key: "/announcing/createAnnouncing",
//     //       icon: <AppstoreAddOutlined />
//     //     },
//     //     {
//     //       title: "公告列表",
//     //       key: "/announcing/listAnnouncing",
//     //       icon: <UnorderedListOutlined />
//     //     }
//     //   ]
//     // },
//   ];
function getItem(label, key, icon, auth, children) {
  return {
    label,
    key,
    icon,
    auth,
    children
  };
}

export const menus = [
  // getItem('首页',"/",<HomeOutlined />, 0,[
  //   getItem('banner','/banenr','',0,[
  //     getItem('新建走马灯','banner/createBanner'),
  //     getItem('走马灯列表','/banner/bannerList')
  //   ]),
  //   getItem('首页物业列表','/recommendList','',0)
  // ]),
  getItem("管理公司",'/company',<FormOutlined />,0,[
    getItem("新建管理公司",'company/createCompany'),
    getItem("管理公司列表",'company/companyList')
  ]),
  getItem('物业管理','/property',<BankOutlined />,0,[
    getItem('新建物业','property/createProperty'),
    getItem('物业列表','property/propertyList')
  ]),
  // getItem('房屋管理','/room',<FormOutlined />,0,[
  //   getItem('新建房屋','room/createRoom'),
  //   getItem('房屋列表','room/roomList')
  // ]),
  getItem("预约记录",'/record',<UnorderedListOutlined />,0),
  getItem("成员管理",'userManagement',<TeamOutlined />,0)
]