import serviceAxios from "..";

const token = localStorage.getItem('btl-token');


export const createPropertyInfo = (data) => {

    return serviceAxios({
        url: `/estate/create`,
        method: "post",
        data,
        headers: {
            'x-token': token
        }
    })
}

// 获取物业列表
export const getPropertyListInfo = (data) => {
    const { page, pageSize, id } = data
    return serviceAxios({
        url: id ? `/estate/list?page=${page}&pageSize=${pageSize}&companyID=${id}` : `/estate/list?page=${page}&pageSize=${pageSize}`,
        method: 'get',
        headers: {
            'x-token': token
        }
    })
}

// 获取所有物业
export const getAllPropertyList = (data) => {
    const { page, pageSize, id } = data
    return serviceAxios({
        url: `/estate/list?page=${page}&pageSize=${pageSize}&companyID=${id}`,
        method: 'get',
        headers: {
            'x-token': token
        }
    })
}
// // 修改物业权重
// export const editPropertyWeight = (data) => {

//     return serviceAxios({
//         url: `/estate/create`,
//         method: "post",
//         data,
//         headers: {
//             'x-token': token
//         }
//     })
// }

// 更新物业信息
export const editPropertyInfo = (data) => {
    return serviceAxios({
        url: `/estate/update`,
        method: "post",
        data,
        headers: {
            'x-token': token
        }
    })
}

// 删除物业信息
export const deleteProperty = (data) => {
    return serviceAxios({
        url: `/estate/delete`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

// 获取全部物业列表
export const getAllProperty = () => {
    return serviceAxios({
        url: '/estate/all',
        method: 'get',
        headers: {
            'x-token': token
        }
    })
}

// 修改物业状态
export const updatePropertyStatus = (data) => {
    return serviceAxios({
        url: `/estate/status`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

// 更新物业权重
export const updatePropertyWeights = (data) => {
    return serviceAxios({
        url: `/estate/weight`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

// 上传图片
export const uploadImg = (data) => {
    return serviceAxios({
        url: `/file/uploads`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}
