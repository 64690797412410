import { Select,Space, Modal, message, Tooltip, Input } from "antd"
import {DownOutlined} from '@ant-design/icons';
import '../../style/Record.scss'
import DisplayList from "../../components/DisplayList/DisplayList"
import { getRecordListInfo,updateRecordStatus } from "../../request/api/room"
import { getAllProperty } from "../../request/api/property"
import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"


export default function RecordList () {
    const navigate = useNavigate();
    const {confirm} = Modal

    let [allData,setAllData] = useState([])
    let [listData,setListData] = useState([])
    let [allListData,setAllListData] = useState([])
    // 物业列表数据
    let [propertyList,setPropertyList] = useState([])
    // 选择的物业
    let [checkProperty,setCheckProperty] = useState(0)
    // 修改Modal
    let [showModal,setShowModal] = useState(false)
    // 修改行数据
    let [rowData,setRowData] = useState({});
    // 复制文本
    let [copyText,setCopyText] = useState()

    let [page,setPage] = useState(1)
    let [pageSize,setPageSize] = useState(10)
    let [total,setTotal] = useState();

    const options = [
        {
            label: "物业列表",
            value: 'property'
        }
    ]

    const statusOptions = [
        {
            value: 1,
            label: "待对应"
        },
        {
            value: 2,
            label: "看房济"
        },
    ]

    const statusChange = (id,val) => {
        confirm({
            title: "是否需要修改这个预约记录的状态？",
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk(){
                editStatus(id,val)
            }
        })
    }

    const editStatus = async (id,val) => {
        let obj = {
            id,
            status: val
        }
        await updateRecordStatus(obj)
        .then((res) => {
            if (res.code === 0) {
                message.success(res.msg)
                setTimeout(()=>{
                    navigate(0)
                },500)
            }else{
                message.error(res.msg)
            }
        }).catch((err) => {
            
        });
    }

    const recordCol = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "姓名",
            dataIndex: "name",
            key: 'name'
        },
        {
            title: "状态",
            dataIndex: "status",
            filters: [
                {
                    value: 1,
                    text: "仅查看待对应"
                },
                {
                    value: 2,
                    text: "仅显示看房济"
                },
            ],
            onFilter: (value,record) => String(record.status).indexOf(value) === 0,
            key: "status",
            filterIcon: (filtered, column) => {
                return <DownOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            },
            render: (_,record) => (
                // <div>
                //     <span>满室</span>
                //     <Switch checked={record.status} onChange={()=>fullChange(record.id,record.status)} />
                // </div>
                <Select 
                    options={statusOptions}
                    value={record.status}
                    bordered={false}
                    // style={{
                    //     width: 100
                    // }}
                    onChange={(e)=>statusChange(record.id,e)}
                />
            )
        },
        {
            title: "电话",
            dataIndex: "tel",
            key: "tel"
        },
        {
            title: "邮箱",
            dataIndex: "mail",
            key: "mail"
        },
        {
            title: "物业名称",
            dataIndex: "property",
            key: "property"
        },
        {
            title: "房屋",
            dataIndex: "room",
            key: "room"
        },
        {
            title: "留言",
            dataIndex: "desc",
            key: "desc",
            render: (_,record) => (
                <Tooltip title={record.desc} color="white" placement="bottomLeft">
                    <div style={{cursor: "pointer"}}>
                        {record.desc}
                    </div>
                </Tooltip>
            )
        },
        {
            title: "预约时间",
            dataIndex: "time",
            key: "time"
        },
        {
            title: "备注",
            dataIndex: "remark",
            key: "remark",
            width: 100,
            ellipsis: true
        },
        {
            title: "操作",
            dataIndex: "action",
            key: "action",
            fixed: 'right',
            render: (_,record) => (
                <Space>
                    <a onClick={()=>changeModal(record.id)}>编辑</a>
                    <Input.TextArea id="copyInput" value={copyText} style={{position: "absolute",left: "-9999px"}} />
                    <a onClick={()=>copyHandle(record)}>复制</a>
                </Space>
            )
        }
    ]

    const copyHandle = (val) => {
        let copyText1 = `
            \n
            お名前*:\n
            ${val.name}\n
            \n
            状态：\n
            ${val.status === 1 ? '待对应' : "看房济"}\n
            \n
            電話番号*:\n
            ${val.tel}\n
            \n
            Email アドレス*:\n
            ${val.mail}\n
            \n
            物件名*:\n
            ${val.property}\n
            ${val.room}\n
            \n
            お問い合わせ内容\n
            ${val.desc}\n
            \n
            预约时间：\n
            ${val.time}\n
        `
        copyText = copyText1;
        setCopyText(copyText);
        
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(copyText)
              .then(() => {
                console.log('文本已成功复制到剪贴板');
                message.success("复制成功")
              })
              .catch((error) => {
                console.error('无法将文本复制到剪贴板:', error);
              });
          } else {
            const inputEle = document.querySelector('#copyInput');
            inputEle.value = copyText
            inputEle.select();
            document.execCommand('copy');
          }
        // navigator.clipboard.writeText(copyText)
        // .then((res) => {
        //     message.success("复制成功")
        // }).catch((err) => {
            
        // });
    }

    const toDate = (data) => {
        let time = new Date(data);
        let Y = time.getFullYear();
        let Mon = time.getMonth() + 1;
        let Day = time.getDate();
        let H = time.getHours();
        let Min = time.getMinutes();
        if (Mon < 10) {
            Mon = '0'+Mon
        }
        if (Day < 10) {
            Day = '0'+Day
        }
        if (H < 10) {
            H = '0'+H
        }
        if (Min < 10) {
            Min = '0'+Min
        }
        return Y+'-'+Mon+'-'+Day+' '+H+':'+Min
    }

    const getList = async () => {
        await getRecordListInfo({
            page,
            pageSize
        })
        .then((res) => {
            if (res.code===0 && res.data.list) {
                let arr = []
                allData = res.data.list;
                setAllData([...allData])
                total = res.data.total
                setTotal(total)
                res.data.list.map((e,i)=>{
                    let obj = {
                        key: e.id,
                        id: e.id,
                        name: e.name,
                        tel: e.telephone,
                        mail: e.email,
                        property: e.estate.name,
                        room: e.suite,
                        time: toDate(e.time * 1000),
                        propertyId: e.estate.id,
                        status: e.status,
                        desc: e.description,
                        remark: e.remark
                    }
                    arr.push(obj)
                })
                listData = arr;
                allListData = arr;
                setAllListData([...allListData])
                setListData([...listData])
            }
        }).catch((err) => {
            
        });
    }

    const getProperty = async () => {
        await getAllProperty()
        .then((res) => {
            console.log("res===",res);
            if (res.code===0&&res.data.list) {
                let arr = [{
                    value: 0,
                    label:'全部预约记录'
                }]
                res.data.list.map((e,i)=>{
                    let obj = {
                        value: e.id,
                        label: e.name
                    }
                    arr.push(obj)
                })
                propertyList = arr;
                setPropertyList([...propertyList])
            }
        }).catch((err) => {
            
        });
    }

    const propertyChange = (val) => {
        checkProperty = val;
        setCheckProperty(checkProperty)
    }

    const changeModal = (id) => {
        setShowModal(true);
        allData.map((e,i)=>{
            if (e.id === id) {
                console.log(e);
                rowData = e;
                setRowData({...rowData})
            }
        })
    }
    
    useEffect(()=>{
        let arr = []
        if (checkProperty === 0) {
            listData = allListData;
            setListData([...listData])
        }
        if (allListData.length > 0 && checkProperty !== 0) {
            allListData.map((e,i)=>{
                if (e.propertyId == checkProperty) {
                    arr.push(e)
                }
            })
            listData = arr;
            setListData([...listData])
        }
    },[checkProperty])

    useEffect(()=>{
        getProperty()
        getList()
    },[page,pageSize])

    return <div className="RecordList">
        <div className="list-title">
            <Select 
                options={propertyList}
                bordered={false}
                value={checkProperty}
                onChange={propertyChange}
            />
        </div>
        <DisplayList 
            type={'record'} 
            recordCol={recordCol} 
            listData={listData} 
            showModal={showModal}
            setShowModal={setShowModal}
            rowData={rowData}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            total={total}
        />
    </div>
} 

