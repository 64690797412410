import { Button, Input, Modal, message, Upload } from 'antd';
import UploadImg from '../../components/Upload/UploadImg';
import '../../style/Personal.scss';
import { useEffect, useState } from 'react';
import { modifyPwd, editPersonalInfo } from '../../request/api/user';
import { useNavigate } from 'react-router-dom';

export default function Personal () {
    const user = JSON.parse(localStorage.getItem("btl-user"));
    const navigate = useNavigate()

    let [userInfo,setUserInfo] = useState({
        nickname: "",
        avatar: ""
    })
    let [passwdModal,setPasswdModal] = useState(false)
    let [oldPasswd,setOldPasswd] = useState();
    let [newPasswd,setNewPasswd] = useState();
    let [confirmPasswd,setConfirmPasswd] = useState()
    let [avatarHash,setAvatarHash] = useState();
    let [imgList,setImgList] = useState([])


    const nicknameChange = (val) => {
        userInfo.nickname = val;
        setUserInfo({...userInfo})
    }

    const saveInfo = async () => {
        let obj = {
            nickname: userInfo.nickname,
            avatar: avatarHash
        }
        await editPersonalInfo(obj)
        .then((res) => {
            console.log("res====>",res);
            if (res.code === 0) {
                message.success("保存成功")
                userInfo.avatar = avatarHash;
                setUserInfo({...userInfo})
                localStorage.setItem('btl-user',JSON.stringify(userInfo))
                setTimeout(()=>{
                    navigate(0)
                },500)
            }else {
                message.error(res.msg)
            }
        }).catch((err) => {
            
        });
    }

    const passwdChange = async () => {
        await modifyPwd({
            password: oldPasswd,
            newPassword: newPasswd,
            repeatnewPassword: confirmPasswd
        })
        .then((res) => {
            if (res.code===0) {
                message.success("修改成功，请重新登录")
                setTimeout(()=>{
                    localStorage.removeItem("btl-token")
                    localStorage.removeItem("btl-user")
                    localStorage.removeItem("authorityId")
                    navigate(0)
                },1000)
            }else{
                message.error(res.msg)
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(()=>{
        userInfo = user
        avatarHash = user.avatar
        setAvatarHash(avatarHash)
        setUserInfo({...userInfo})
    },[])


    return <div className="Personal">
        <div className="personal-title">
            <h3>基本资料</h3>
        </div>
        <div className="personal-content">
            <div className="avator item">
                <h3>头像</h3>
                <UploadImg hash={avatarHash} setHash={setAvatarHash} type="personal" imgList={imgList} setImgList={setImgList} />
                
            </div>
            <div className='user-name item'>
                <h3>姓名</h3>
                <Input value={userInfo.nickname} onChange={(e)=>nicknameChange(e.target.value)} />
            </div>
            <div className='user-account item'>
                <h3>登录账号</h3>
                <Input value={user?.username} />
            </div>
            <div className='user-password item'>
                <div className='pwd'>
                    <h3>密码</h3>
                    <span onClick={()=>setPasswdModal(true)}>更改密码</span>
                </div>
                <Input.Password value={11111111} disabled />
            </div>
        </div>
        <Button type='primary' onClick={()=>saveInfo()}>保存</Button>
        <Modal 
            open={passwdModal} 
            footer={null} 
            onCancel={()=>setPasswdModal(false)}
            title={"修改密码"}
            className="passwdModal"
        >
            <p><span>*</span>旧密码</p> 
            <Input.Password placeholder="请输入新密码" value={oldPasswd} onChange={(e)=>setOldPasswd(e.target.value)} />
            <p><span>*</span>新密码</p>
            <Input.Password placeholder="请输入新密码" value={newPasswd} onChange={(e)=>setNewPasswd(e.target.value)} />
            <p><span>*</span>再次输入新密码</p>
            <Input.Password placeholder="请再次输入新密码" value={confirmPasswd} onChange={(e)=>setConfirmPasswd(e.target.value)} />
            <div className="btn">
                <Button type="primary" onClick={passwdChange}>确认重置</Button>
            </div>
        </Modal>
    </div>
}


