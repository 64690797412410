import { useLocation, useRoutes } from "react-router-dom";
import routes from "../../router"
import { convertToken } from "../../utils/convert/convertToken";
import ToLogin from "../CustomNavigateTo/ToLogin";
import ToHome from "../CustomNavigateTo/ToHome";
import ToRelogin from "../CustomNavigateTo/ToRelogin";
import DefaultLayout from "../../containers/DefaultLayout";
import { useEffect } from "react";


export default function BeforeRouterEnter() {
  
    const location = useLocation()
    let token = localStorage.getItem("btl-token");

    //1、如果访问的是登录页面， 并且有token， 跳转到首页
    if(location.pathname==="/login" && token){
      // 这里不能直接用 useNavigate 来实现跳转 ，因为需要BeforeRouterEnter是一个正常的JSX组件
      return <ToHome />
    }
    //2、如果访问的不是登录页面，并且没有token， 跳转到登录页
    if(location.pathname!=="/login" && !token){
      return <ToLogin />
    }

    // 确认token是否过期
    // if (token && !convertToken(token)) {
    //   return <ToRelogin />
    // }
  
    // return outlet
    return <DefaultLayout />
}