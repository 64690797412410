import '../../style/Banner.scss'
import { Space, Table, Tag , message , Input ,Button ,Popconfirm } from 'antd';
import { useState } from 'react'
import {SearchOutlined} from "@ant-design/icons"

export default function BannerList () {
    const [messageApi] = message.useMessage();

    //权重变化时调用
    const weightsChange = (val,id) => {
        // 修改位置
        let modifyLocation=null
        // 最大权重
        let maxWeights=data[0].weights
        //权重值是否重复
        let isRepetitionWeight=false
        for(let i in data){
            //记录修改权重的位置
            if(data[i].id==id){
                modifyLocation=i
                continue;
            }
            //判断是否有重复权重值
            if(data[i].weights==val){
                isRepetitionWeight=true
            }
            //记录目前最大权重
            if(data[i].weights>maxWeights){
                maxWeights=data[i].weights
            }
        }
        // console.log("最大权重值",maxWeights)
        // console.log("修改权重的位置",modifyLocation)
        // console.log("当前权重是否重复",isRepetitionWeight)
        if (isRepetitionWeight){
            message.warning("权重数值重复，当前最大为权重值为"+ maxWeights,1)
            return
        }
        //修改权重
        data[modifyLocation].weights=val
        setData([...data])
    }

    //根据权重排序
    const weightsSort = () =>{
        let toSort=[data[0]]
        for (let i=1;i<data.length;i++){
            for (let k in toSort){
                if(data[i].weights>toSort[k].weights){
                    toSort.splice(k,0,data[i]);
                    break;
                }
                if(k==toSort.length-1){
                    toSort.splice(k+1,0,data[i]);
                }
            }
        }
        // 新排序结果
        data=toSort
        setData([...data])
    }

    const confirm = () => {
        message.info('删除成功。');
      };

    const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          render: (text) => <a>{text}</a>,
        },
        {
          title: '权重',
          dataIndex: 'weights',
          key: 'weights',
          render: (_, record) => (
            <input type="text" value={record.weights} onBlur={()=>weightsSort()} onChange={(e)=>weightsChange(e.target.value,record.id)}></input>
          ),
        },
        {
          title: '图片',
          dataIndex: 'img',
          key: 'img',
        },
        {
            title: '标题',
            dataIndex: 'title',
            key: "title"
        },
        {
            title: '小标题',
            dataIndex: 'subtitle',
            key: "subtitle"
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <a>编辑</a>
                <Popconfirm
                    placement="topRight"
                    title="删除"
                    description="确认删除？" 
                    onConfirm={confirm}
                    okText="确定"
                    cancelText="取消"
                >
                    <a>移除</a>
                </Popconfirm>
            </Space>
          ),
        },
    ];
    
    let [data,setData] = useState([
        {
          id: '1',
          weights: '5',
          img: '1',
          title: '海纳',
          subtitle: "很长的文字要展示但是要留下...尾巴",
          createTime: '2019-12-23 14:07'
        },
        {
            id: '2',
            weights: '2',
            img: '1',
            title: '海纳',
            subtitle: "很长的文字要展示但是要留下...尾巴",
            createTime: '2019-12-23 14:07'
        },
        {
            id: '3',
            weights: '1',
            img: '1',
            title: '海纳',
            subtitle: "很长的文字要展示但是要留下...尾巴",
            createTime: '2019-12-23 14:07'
        }
    ])

    const pagination = {
        showSizeChanger: true,
        
    }

    return <div className="BannerList">
        <div className="title-box">
            <h1 className="title">Banner列表</h1>
            <div className="title-right">
                <Input 
                    style={{width: 160}}
                />
                <div className="search-icon">
                    <SearchOutlined />
                </div>
                <Button type="primary">新建房屋</Button>
            </div>
        </div>
        <div className="content">
            <Table columns={columns} dataSource={data}
                pagination={pagination}
            />
        </div>
    </div>
}