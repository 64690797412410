import { Input, Select, Checkbox, Row, Col, Button, message } from "antd";
import { useEffect, useState } from "react";
import { createUser, editUser } from "../../request/api/user";
import { useNavigate } from "react-router-dom";


const CheckboxGroup = Checkbox.Group;

export default function CreateUserModal (params) {
    const navigate = useNavigate()
    const {setModalOpen, rowData, edit} = params

    const options = [
        // {
        //     label: "普通成员",
        //     value: "1"
        // },
        {
            label: "管理员",
            value: "888"
        }
    ]

    let [userInfo,setUserInfo] = useState({
        account: '',
        pwd: '',
        nickname: '',
        role: '888'
    })
    let [roleChange,setRoleChange] = useState([]);
    let [checkAll,setCheckAll] = useState(false)

    const valueChange = (type,data) => {
        userInfo[type] = data;
        setUserInfo({...userInfo})
    }

    const create = async () => {
        let obj = {
            username: userInfo.account,
            nickname: userInfo.nickname,
            password: userInfo.pwd,
            avatar: '',
            authorityId: userInfo.role,
            authoritySourceIds: roleChange
        }
        await createUser(obj)
        .then((res) => {
            if (res.code === 0) {
                message.success(res.msg)
                setTimeout(()=>{
                    navigate(0)
                },600)
            }else{
                message.error(res.msg)
            }
        }).catch((err) => {
            
        });
    }

    const changValues = (values) => {
        roleChange = values;
        setRoleChange([...roleChange])
    } 

    const checkAllChange = () => {
        checkAll = !checkAll;
        setCheckAll(checkAll)
    }

    const editHandle = async () => {
        let obj = {
            id: rowData.key,
            username: userInfo.account,
            nickname: userInfo.nickname,
            password: userInfo.pwd,
            avatar: '',
            authorityId: userInfo.role,
            authoritySourceIds: roleChange
        }
        await editUser(obj)
        .then((res) => {
            if (res.code===0){
                message.success(res.msg)
                setTimeout(()=>{
                    navigate(0)
                },600)
            }else{
                message.error(res.msg)
            }
        }).catch((err) => {
            
        });
    }

    useEffect(()=>{
        if (checkAll) {
            let arr = [1,2,3,4,5]
            roleChange = arr
            setRoleChange([...roleChange])
        }else{
            let arr = [];
            roleChange = arr;
            setRoleChange([...roleChange])
        }
    },[checkAll])

    useEffect(()=>{
        if (edit && Object.keys(rowData).length > 0) {
            console.log("rowData===",rowData);
            userInfo.account = rowData.account;
            userInfo.nickname = rowData.name;
            userInfo.role = rowData.role
            if (rowData.role === '管理员') {
                userInfo.role = '888'
            }else{
                userInfo.role = '999'
            }
            let roleArr = []
            rowData.permission.map((e)=>{
                if (e===0) {
                    // roleArr.push(e);
                    setCheckAll(true)
                    roleArr = [1,2,3,4,5]
                }else {
                    roleArr.push(e[1])
                }
            })
            roleChange = roleArr;
            setRoleChange([...roleChange])
        }
    },[rowData])

    useEffect(()=>{
        console.log("checkAll=====",checkAll);
    },[checkAll])


    return <div className="CreateUserModal">
        <div className="create-title">
            <h2>{`${edit ? '编辑' : '添加'}成员`}</h2>
        </div>
        <div className="create-content">
            <div className="content-item">
                <h3>登录账号</h3>
                <Input 
                    value={userInfo.account} 
                    onChange={(e)=>valueChange('account',e.target.value)} 
                    disabled={edit ? true : false}
                />
            </div>
            <div className="content-item">
                <h3>密码</h3>
                <Input value={userInfo.pwd} onChange={(e)=>valueChange('pwd',e.target.value)} />
            </div>
            <div className="content-item">
                <h3>姓名</h3>
                <Input value={userInfo.nickname} onChange={(e)=>valueChange('nickname',e.target.value)} />
            </div>
            <div className="content-item">
                <h3>角色</h3>
                <Select 
                    options={options}
                    value={userInfo.role}
                    onChange={(e)=>valueChange('role',e)}
                />
            </div>
            <div className="content-item">
                <h3>权限管理</h3>
                <div className="permission-check">
                    <div className="check-all">
                        <Checkbox checked={checkAll} onChange={checkAllChange}>全部</Checkbox>
                    </div>
                    <CheckboxGroup
                        value={roleChange}
                        onChange={changValues}
                    >
                        <Row>
                            <Col span={24}>
                                <Checkbox value={3} >管理公司</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value={1} >管理物业</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value={2} >管理房屋</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value={4} >管理预约记录</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value={5}>管理成员</Checkbox>
                            </Col>
                        </Row>
                    </CheckboxGroup>
                </div>
            </div>
        </div>
        {
        
            !edit ? <Button onClick={create} type="primary">确定</Button> : <Button onClick={editHandle} type="primary">修改</Button>
        }
    </div>
}


