import serviceAxios from "..";

const token = localStorage.getItem('btl-token')
// 登录
export const login = (data) => {
    return serviceAxios({
        url: `/user/login`,
        method: "post",
        data,
    });
}

// 获取管理成员列表
export const getUserList = (data) => {
    const { page, pageSize } = data;
    return serviceAxios({
        url: `/member/list?page=${page}&pagesize=${pageSize}`,
        method: "get",
        headers:{
            'x-token': token
        }
    })
} 

// 创建用户
export const createUser = (data) => {
    return serviceAxios({
        url: `/member/register`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
} 

// 修改用户
export const editUser = (data) => {
    return serviceAxios({
        url: `/member/update`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

// 删除用户
export const deleteUser = (data) => {
    return serviceAxios({
        url: `/member/delete`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

// 个人资料修改
export const editPersonalInfo = (data) => {
    return serviceAxios({
        url: `/user/update`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

// 个人密码修改
export const modifyPwd = (data) => {
    return serviceAxios({
        url: `/user/changePassword`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

