import BeforeRouterEnter from "./components/CustomRouter/BeforeRouterEnter"
import { ConfigProvider } from "antd";
import zhCN from 'antd/locale/zh_CN';

function App() {
  return (
    <div className="App">
      <ConfigProvider locale={zhCN}>
        <BeforeRouterEnter /> 
      </ConfigProvider>
    </div>
  );
}

export default App;
