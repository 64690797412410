import serviceAxios from "..";

const token = localStorage.getItem('btl-token');
export const CreateCompanyInfo = (data) => {

    return serviceAxios({
        url: `/company/create`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

// 获取公司列表
export const getCompanyList = (data) => {
    const { page, pageSize } = data
    return serviceAxios({
        url: `/company/list?page=${page}&pageSize=${pageSize}`,
        method: 'get',
        headers: {
            'x-token': token
        }
    })
}

// 修改公司信息
export const updateCompanyInfo = (data) => {

    return serviceAxios({
        url: `/company/update`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

// 删除公司信息
export const deleteCompanyInfo = (data) => {

    return serviceAxios({
        url: `/company/delete`,
        method: 'post',
        data,
        headers: {
            'x-token': token
        }
    })
}

// 获取所有公司
export const getAllCompany = () => {

    return serviceAxios({
        url: '/company/all',
        method: 'get',
        headers: {
            'x-token': token
        }
    })
}
