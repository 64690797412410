import { Select, Space, Modal, message, Switch, Breadcrumb, Input, Button } from "antd"
import {DownOutlined,SearchOutlined} from '@ant-design/icons';
import DisplayList from "../../components/DisplayList/DisplayList"
import { getRoomList, deleteRoomInfo, updateFull, updateStatusInfo, updateWeights } from "../../request/api/room"
import { useEffect, useState, useRef } from "react";
import ListDataDity from "../../utils/ListDataDity";
import { useNavigate, useLocation } from "react-router-dom";
import { getAllProperty } from "../../request/api/property";
import CreateRoom from "./CreateRoom";

export default function RoomList () {
    const { confirm } = Modal
    const navigate = useNavigate()
    const location = useLocation()

    const weightInputRef = useRef(null)

    let [allData,setAllData] = useState([])
    let [roomList,setRoomList] = useState([])
    let [allRoomList,setAllRoomList] = useState([])
    // 修改Modal
    let [showModal,setShowModal] = useState(false)
    // 新建modal
    let [createModal,setCreateModal] = useState(false)
    // 修改行数据
    let [rowData,setRowData] = useState({})
    // 满室状态
    let [isFull,setIsFull] = useState(false)
    // 物业列表数据
    let [propertyList,setPropertyList] = useState([])
    // 选择的物业
    let [checkProperty,setCheckProperty] = useState(0)
    let [breadCol,setBreadCol] = useState([
        {
            path: '/property/propertyList',
            breadcrumbName: '物业列表',
        },
    ])

    let [page,setPage] = useState(1)
    let [pageSize,setPageSize] = useState(10)
    let [total,setTotal] = useState();
    let [changeWeightsId,setChangeWeightsId] = useState();
    let [editWeights,setEditWeights] = useState({
        weight: '',
        id: ''
    })

    const options = [
        {
            label: "物业列表",
            value: "property"
        }
    ]
    const statusOptions = [
        {
            value: 1,
            label: "空置"
        },
        {
            value: 2,
            label: "装修中"
        },
        {
            value: 3,
            label: "租赁中"
        }
    ]
    const roomCol = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '权重',
            dataIndex: 'weights',
            key: 'weights',
            render: (_,record) => (
                <div>
                    {
                        changeWeightsId == record.id ? <Input className="weights" value={editWeights.weight} autoFocus onChange={(e)=>weightsChange(e.target.value,record.id)} onBlur={()=>updateWeight()} ref={weightInputRef} onPressEnter={()=>autoBlur()} /> :
                        <p onClick={()=>setChangeWeightsId(record.id)}>{record.weights}</p>
                    }
                </div>
            )
        },
        {
            title: '图片',
            dataIndex: 'img',
            key: 'img',
            render: (_,record) => {
                return <img src={`${process.env.REACT_APP_API_URL}/${record.img.split(",")[0]}`} />
            }
        },
        {
            title: "房号",
            dataIndex: "roomId",
            key: "roomId"
        },
        {
            title: "状态",
            dataIndex: "status",
            filters: [
                {
                    value: 1,
                    text: "空置"
                },
                {
                    value: 2,
                    text: "装修中"
                },
                {
                    value: 3,
                    text: "租赁中"
                }
            ],
            onFilter: (value,record) => String(record.status).indexOf(value) === 0,
            key: "status",
            filterIcon: (filtered, column) => {
                return <DownOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            },
            render: (_,record) => (
                // <div>
                //     <span>满室</span>
                //     <Switch checked={record.status} onChange={()=>fullChange(record.id,record.status)} />
                // </div>
                <Select 
                    options={statusOptions}
                    value={record.status}
                    bordered={false}
                    style={{
                        width: 100
                    }}
                    onChange={(e)=>statusChange(record.id,e)}
                />
            )
        },
        {
            title: "面积",
            dataIndex: "area",
            key: "area",
            render: (text,record) => {
                return <div>
                    <span>{record.area}</span>
                    <span>㎡</span>
                </div>
            }
        },
        {
            title: "租金",
            dataIndex: "rental",
            key: "rental",
            render: (text,record) => {
                return <div>
                    <span>{record.rental}</span>
                    <span>円</span>
                </div>
            }
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            key: "createTime"
        },
        {
            title: "操作",
            dataIndex: "action",
            key: "action",
            fixed: 'right',
            render: (_,record) => (
                <Space>
                    <a onClick={()=>changeModal(record.id)}>编辑</a>
                    <a onClick={()=>delModal(record.id)}>移除</a>
                </Space>
            )
        }
    ]

    const autoBlur = () => {
        weightInputRef.current.blur()
    }

    const weightsChange = (val,id) => {
        editWeights.weight = Number(val);
        editWeights.id = id;
        setEditWeights({...editWeights})
    }

    const updateWeight = async () => {
        if (editWeights.weight) {
            await updateWeights(editWeights)
            .then((res) => {
                if (res.code === 0) {
                    message.success("更新权重成功");
                    editWeights = {
                        weight: '',
                        id: ''
                    }
                    setEditWeights({...editWeights})
                    getList()
                }else{
                    editWeights = {
                        weight: '',
                        id: ''
                    }
                    setEditWeights({...editWeights})
                    message.error("更新权重失败")
                }
            }).catch((err) => {
                
            });
        }
        setChangeWeightsId('')
    }

    const statusChange = (id,val) => {
        confirm({
            title: "是否需要修改这个房屋的状态？",
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk(){
                editStatus(id,val)
            }
        })
    }

    const editStatus = async (id,val) => {
        let obj = {
            id,
            status: val
        }
        await updateStatusInfo(obj)
        .then((res) => {
            if (res.code === 0) {
                message.success(res.msg)
                setTimeout(()=>{
                    navigate(0)
                },500)
            }else{
                message.error(res.msg)
            }
        }).catch((err) => {
            
        });
    }

    const getList = async () => {
        await getRoomList({
            page,
            pageSize,
            estateID: location.search.split("?")[1]
        })
        .then((res) => {
            if (res.code === 0 && res.data.list) {
                allData = res.data.list;
                setAllData([...allData])
                total = res.data.total;
                setTotal(total)
                let arr = ListDataDity({
                    type: 'room',
                    data: res.data.list
                })
                roomList = arr;
                allRoomList = arr;
                setAllRoomList([...allRoomList])
                setRoomList([...roomList]);
            }
        }).catch((err) => {
            
        });
    }

    const delModal = (id) => {
        confirm({
            title: "是否需要删除这这个房屋信息？",
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk(){
                delRoom(id)
            }
        })
    }

    const delRoom = async (id) => {
        await deleteRoomInfo({id:id})
        .then((res)=>{
            if (res.code === 0) {
                message.success(res.msg)
                setTimeout(()=>{
                    navigate(0)
                },1000)
            }else{
                message.error("删除失败")
            }
        })
    }

    const changeModal = (id) => {
        setShowModal(true);
        allData.map((e,i)=>{
            if (e.id === id) {
                rowData = e;
                setRowData({...rowData})
            }
        })
    }

    const fullChange =  (id,status) => {
        isFull = !status;
        setIsFull(isFull)
        let obj = {
            id: id,
            status: isFull
        }
        updateStatus(obj)
    }

    const updateStatus = async (data) => {
        await updateFull(data)
        .then((res) => {
            if(res.code === 0) {
                message.success(res.msg)
                setTimeout(()=>{
                    navigate(0)
                },500)
            }else{
                message.error(res.msg)
            }
        }).catch((err) => {
            
        });
    }

    const getProperty = async () => {
        await getAllProperty()
        .then((res) => {
            if (res.code===0&&res.data.list) {
                let arr = [{
                    value: 0,
                    label:'物业列表'
                }]
                res.data.list.map((e,i)=>{
                    let obj = {
                        value: e.id,
                        label: e.name
                    }
                    arr.push(obj)
                })
                propertyList = arr;
                setPropertyList([...propertyList])
            }
        }).catch((err) => {
            
        });
    }

    const newRoomHandle = () => {
        checkProperty = location.search.split("?")[1]
        setCheckProperty(checkProperty)
        setCreateModal(true)
    }

    // const propertyChange = (val) => {
    //     checkProperty = val;
    //     setCheckProperty(checkProperty)
    // }

    useEffect(()=>{
        getProperty()
        getList()
    },[page,pageSize])

    // useEffect(()=>{
    //     let arr = []
    //     if (checkProperty === 0) {
    //         roomList = allRoomList;
    //         setRoomList([...roomList])
    //     }
    //     if (allRoomList.length > 0 && checkProperty !== 0) {
    //         allRoomList.map((e,i)=>{
    //             if (e.property == checkProperty) {
    //                 arr.push(e)
    //             }
    //         })
    //         roomList = arr;
    //         setRoomList([...roomList])
    //     }
    // },[checkProperty])

    useEffect(()=>{
        if (location) {
            propertyList.map((e,i)=>{
                if (e.value == location.search.split('?')[1]) {
                    let hadID = false
                    breadCol.map((ele)=>{
                        if (ele.path.indexOf(`${e.value}`) > -1) {
                            hadID = true
                        }
                    })
                    let updatedBreadCol = breadCol
                    if (!hadID) {
                        updatedBreadCol.push({
                            path: `/room/roomList?${e.value}`,
                            breadcrumbName: e.label,
                        })
                    }
                    setBreadCol(updatedBreadCol)
                }
            })
        }
    },[location,propertyList])


    return <div className="RoomList">
        <div className="list-title">
            {/* <Select 
                options={propertyList}
                value={checkProperty}
                bordered={false}
                onChange={propertyChange}
            /> */}
            <Breadcrumb 
                separator=">"
                routes={breadCol}
            />
            <div className="title-right">
                <Input 
                    style={{width: 160}}
                />
                <div className="search-icon">
                    <SearchOutlined />
                </div>
                <Button type="primary" onClick={()=>newRoomHandle()}>新建房屋</Button>
            </div>
        </div>
        <DisplayList 
            type='room' 
            listData={roomList} 
            listItem={roomCol} 
            showModal={showModal}
            setShowModal={setShowModal}
            rowData={rowData}
            status={isFull}
            setBreadCol={setBreadCol}
            breadCol={breadCol}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            total={total}
        />
        <Modal
            open={createModal}
            onCancel={()=>setCreateModal(false)}
            className="createModal roomModal"
            footer={false}
            destroyOnClose
        >
            <CreateRoom setCreateModal={setCreateModal} checkProperty={checkProperty} />
        </Modal>
    </div>
}


