import { message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// 去往登录页的组件
export default function ToLogin(){
  const navigateTo = useNavigate()
  // 加载完这个组件之后实现跳转
  useEffect(()=>{
    // 加载完组件之后执行这里的代码
    navigateTo("/login");
    message.warning("您还没有登录，请登录后再访问！");
  },[])

  return <h1>ToLogin</h1>
}