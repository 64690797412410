import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { menus } from "./menu";

// 处理 pathname
function getOpenKeys(string) {
    let newStr = "",
    newArr = [],
    arr = string.split("/").map(i => "/" + i);
    for (let i = 1; i < arr.length - 1; i++) {
      newStr += arr[i];
      newArr.push(newStr);
    }
    return newArr;
}

function getItem(label, key, icon, auth, children) {
    return {
      key,
      icon,
      children,
      auth,
      label,
    };
}

const AppMenu = props => {
  
    const [state, setstate] = useState({
        openKeys: [],
        selectedKeys: []
    });

    const rootSubmenuKeys = ['/property', '/room', '/company', '/record'];
    

    let [openKeys, setOpenKeys] = useState([]);
    let [selectKey,setSelectKey] = useState()


    // let { openKeys, selectedKeys } = state;
    const location = useLocation();
    // const { menu } = props;
    let [menu,setMenu] = useState([])
    const navigateTo = useNavigate()

    const getMenu = m => {
      let newMenu,
        auth = JSON.parse(localStorage.getItem("user"))?.auth;
      if (!auth) {
        menu = m
        setMenu([...menu])
        // return menu;
      } else {
        newMenu = menu.filter(res => res.auth && res.auth.indexOf(auth) !== -1);
        // return newMenu;
        menu = newMenu;
        setMenu([...menu])
      }
    };

    const initMenu = () => {

    }

  const renderMenuItem = ({ key, icon, title }) => (
    <Menu.Item key={key}>
      <Link to={key}>
        {icon}
        <span>{title}</span>
      </Link>
    </Menu.Item>
  );

  // 循环遍历数组中的子项 subs ，生成子级 menu
  const renderSubMenu = ({ key, icon, title, subs }) => {
    return (
      <Menu.SubMenu
        key={key}
        title={
          <span>
            {icon}
            <span>{title}</span>
          </span>
        }
      >
        {subs &&
          subs.map(item => {
            return item.subs && item.subs.length > 0
              ? renderSubMenu(item)
              : renderMenuItem(item);
          })}
      </Menu.SubMenu>
    );
  };

  // 只展开一个 SubMenu
  // const onOpenChange = openKeys => {
  //     setstate(prevState => {
  //       if (openKeys.length === 0 || openKeys.length === 1) {
  //         return { ...prevState, openKeys };
  //       }
  //       const latestOpenKey = openKeys[openKeys.length - 1];
  
  //       // 这里与定义的路由规则有关
  //       if (latestOpenKey.includes(openKeys[0])) {
  //         return { ...prevState, openKeys };
  //       } else {
  //         return { ...prevState, openKeys: [latestOpenKey] };
  //       }
  //     });
  //   };
  const onOpenChange = (keys) => {

    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      openKeys = keys
      setOpenKeys(openKeys);
    } else {
      openKeys = latestOpenKey ? [latestOpenKey] : []
      setOpenKeys(openKeys);
    }
    console.log("openKeys====",openKeys);
};
    const handelMenu = (key) => {
        setstate(prevState => ({ ...prevState, selectedKeys: [key] }));
        navigateTo(key);
    }
  
    // 页面刷新的时候可以定位到 menu 显示
    useEffect(() => {
        let pathname = location.pathname;
        setstate(prevState => {
        return {
            ...prevState,
            selectedKeys: [pathname],
            openKeys: getOpenKeys(pathname)
        };
        });
    }, [props]);

    useEffect(()=>{
        if (location) {
            let path = location.pathname;
            if (path.indexOf('/property') > -1) {
                openKeys = ['/banner',path]
            }else if (path.indexOf('/room') > -1) {
                openKeys = ['/room',path]
            }else if (path.indexOf('/company')> -1) {
                openKeys = ['/company',path]
            }
            setOpenKeys([...openKeys])
            selectKey = path;
            setSelectKey(selectKey)
        }
    },[])


  return (
    <Menu
      mode="inline"
      // theme="dark"
      openKeys={openKeys}
      selectedKeys={selectKey}
      onClick={({ key }) => handelMenu(key)}
      onOpenChange={onOpenChange}


      defaultSelectedKeys={['/home']}
      items={menus}
      
    />
  );
};

export default AppMenu;
