import { message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// 去往登录页的组件
export default function ToHome(){
  const navigateTo = useNavigate()
  // 加载完这个组件之后实现跳转
  useEffect(()=>{
    // 加载完组件之后执行这里的代码
    navigateTo("/");
    message.warning("您已经登录过了！");
  },[])
  return <div></div>
}