import { Upload, Modal, Radio, Button, Input } from 'antd';
import { EditFilled } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { useEffect, useState } from 'react';
import ImageCropper from './ImageCropper';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';
import { useLocation } from 'react-router-dom';


const DraggableUploadListItem = ({ originNode, file, step, imageDesc, setImageDesc, setDndkitDisable, clearCount, setClearCount, isClear }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: file.uid,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  let [checkImage,setCheckImage] = useState();
  let [descModal,setDescModal] = useState(false);
  let [descValue,setDescValue] = useState()

  const className = isDragging
    ? css`
        a {
          pointer-events: none;
        }
      `
    : '';

  const editDesc = () => {
    let desc = imageDesc[file.uid]
    checkImage = file.uid
    setCheckImage(checkImage)
    setDescValue(desc)
    setDescModal(true)
    setDndkitDisable(true)
  }

  const descChange = (val) => {
    setDescValue(val)
  }

  const setDescHandle = () => {
    let obj = imageDesc;
    let uid = file.uid
    obj[uid] = descValue
    setImageDesc({...obj})
    setDescModal(false);
    setDndkitDisable(false)
    setDescValue('')
  }




  return (
    <div ref={setNodeRef} style={style} className={`${className} ant-upload-list-item`} {...attributes} {...listeners}>
      <div className='upload-img'>
        {originNode.props.children}
      </div>
      {
        step ? <div className='surrounding-desc'>
        <p className='desc-text'>{imageDesc[file.uid] ? imageDesc[file.uid] : <span className='tip'>请添加描述</span>}</p>
        <EditFilled onClick={()=>editDesc()} />
      </div> : ""
      }
      <Modal
        open={descModal}
        onCancel={()=>setDescModal(false)}
        title={"添加描述"}
        footer={false}
        className='descModal'
        destroyOnClose
      >
        <div className='desc-content'>
          <Input.TextArea maxLength={30} showCount value={descValue} onChange={(e)=>descChange(e.target.value)} />
          <Button type='primary' onClick={()=>setDescHandle()} >确认</Button>
        </div>
      </Modal>
    </div>
  );
};




const getBase64 = (file) =>
new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function CropUpload (params) {
    const { 
        fileList, 
        setFileList, 
        uploadButton, 
        cropImg, 
        setCropImg, 
        cropImgName, 
        setCropImgName, 
        uploadImage,
        cropImageId,
        setCropImageId,
        step,
        imageDesc,
        setImageDesc 
    } = params

    const location = useLocation()


    // const [fileList, setFileList] = useState([
    //     // {
    //     //   uid: '-1',
    //     //   name: 'image.png',
    //     //   status: 'done',
    //     //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    //     // },
    //   ]);
    let [cropModal,setCropModal] = useState(false)
    let [previewImg,setPreviewImg] = useState()
    let [startCrop,setStartCrop] = useState(false)
    let [checkUpload,setCheckUpload] = useState(2)
    // 拖拽是否启用
    let [dndkitDisabled,setDndkitDisable] = useState(false)
    // 清除预览按钮的次数
    let [clearCount,setClearCount] = useState(0);
    // 是否需要清除预览按钮
    let [isClear,setIsClear] = useState(false)


      function convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      }

      const onChange = (info) => {
        let listArr = info.fileList;
        listArr.map((e,i)=>{
          e.uid = i + 1
        })
        setFileList(listArr);
      };

      const handlePreview = async (file) => {
          if (!file.status) {
            if (!file.url && !file.preview) {
              if (file.originFileObj?.size) {
                  file.preview = await getBase64(file.originFileObj);
              }else{
                  file.preview = await getBase64(file);
              }
            }
            previewImg = file.preview;
            setPreviewImg(previewImg);
            setCropImgName(file.name);
            setCropImageId(file.uid)
            setCropModal(true);
          }else{
            window.open(file.url)
          }

      }

      const beforeUpload = (file) => {
        convertFileToBase64(file)
        .then((res) => {
            file.thumbUrl = res
        }).catch((err) => {
            
        });
        setFileList([...fileList, file]);
        return false;
      }

      const beforeUploadMore = (file) => {
        return false
      }

      const onRemove = (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      }

      const sensor = useSensor(PointerSensor, {
        activationConstraint: {
          distance: 10,
        },
      });

      const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
          setFileList((prev) => {
            const activeIndex = prev.findIndex((i) => i.uid === active.id);
            const overIndex = prev.findIndex((i) => i.uid === over?.id);
            return arrayMove(prev, activeIndex, overIndex);
          });
        }
      };

      useEffect(()=>{
        let path = location.pathname
        let isCreate = path.indexOf('createProperty');
        if (isCreate > -1) {
          setIsClear(false)
        }else{
          setIsClear(true)
        }
      },[location])



      const modalProps = {

      }

      const onCrop = (data) => {
        //   console.log("img===",data);
      }

      const radioChange = (e) => {
        setCheckUpload(e.target.value)
      }


    return (
        <div className='CropUpload'>
            {
                checkUpload === 1 ?
                <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                  <SortableContext items={fileList.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
                    <ImgCrop 
                        rotationSlider
                        modalProps
                        aspect={16 / 9}
                        quality={1}
                    >
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            beforeUpload={beforeUpload}
                            onRemove={onRemove}
                            multiple={true}
                        >
                            {uploadButton}
                        </Upload>
                    </ImgCrop>
                  </SortableContext>
                </DndContext>
                :
                <DndContext sensors={[sensor]} onDragEnd={onDragEnd} >
                  <SortableContext items={fileList.map((i) => i.uid)} disabled={dndkitDisabled ? true : false}  strategy={verticalListSortingStrategy}>
                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={handlePreview}
                        beforeUpload={beforeUploadMore}
                        onRemove={onRemove}
                        multiple={true}
                        itemRender={(originNode, file)=>(
                          <DraggableUploadListItem 
                            setDndkitDisable={setDndkitDisable}  
                            originNode={originNode} 
                            file={file} 
                            step={step} 
                            imageDesc={imageDesc} 
                            setImageDesc={setImageDesc} 
                            clearCount={clearCount}
                            setClearCount={setClearCount}
                            isClear={isClear}
                          />
                        )}
                        
                    >
                        {uploadButton}
                    </Upload>
                  </SortableContext>
                </DndContext>
            }
            <Modal
                open={cropModal}
                onCancel={()=>setCropModal(false)}
                className='cropperModal'
                footer={false}
                maskClosable={false}
                closeIcon={false}
            >
                <ImageCropper 
                    image={previewImg} 
                    onCrop={onCrop} img={cropImg} 
                    setImg={setCropImg} 
                    setStartCrop={setStartCrop}
                    setCropModal={setCropModal}
                />
            </Modal>
        </div>
      );
}


