import DisplayList from "../../components/DisplayList/DisplayList"
import { Button, Select, Input, Space, Modal, message, Dropdown, Breadcrumb } from "antd"
import {SearchOutlined,DownOutlined} from "@ant-design/icons"
import { getPropertyListInfo, deleteProperty, updatePropertyStatus, updatePropertyWeights } from "../../request/api/property"
import { useEffect, useState, useRef } from "react"
import ListDataDity from '../../utils/ListDataDity'
import { getAllCompany } from "../../request/api/company"
import { useNavigate, useLocation, Link } from "react-router-dom"


export default function PropertyList () {
    const { confirm } = Modal
    const navigate = useNavigate()
    const location = useLocation()

    const weightInputRef = useRef(null)

    let [listData,setListData] = useState([])
    let [allListData,setAllListData] = useState([])
    let [companyList,setCompanyList] = useState([])
    //全部数据
    let [allData,setAllData] = useState([])
    // 修改Modal
    let [showModal,setShowModal] = useState(false)
    // 修改行数据
    let [rowData,setRowData] = useState({})
    // 选择的公司
    let [checkCompany,setCheckCompany] = useState(0)
    let [breadCol,setBreadCol] = useState([
        {
            path: '/property/propertyList',
            breadcrumbName: '物业列表',
        },
    ])
    let [page,setPage] = useState(1)
    let [pageSize,setPageSize] = useState(10)
    let [total,setTotal] = useState();
    // 管理公司表头值
    let [selectCompany,setSelectCompany] = useState(location.search.split('?')[1] ? Number(location.search.split('?')[1]) : 0)
    let [changeWeightsId,setChangeWeightsId] = useState();
    let [editWeights,setEditWeights] = useState({
        weight: '',
        id: ''
    })

    // const BreadCol=[
        
    //     {
    //       path: '/user',
    //       breadcrumbName: 'User',
    //     },
    // ]

    const propertyCol = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '权重',
            dataIndex: 'weights',
            key: 'weights',
            render: (_,record) => (
                <div>
                    {
                        changeWeightsId == record.id ? <Input className="weights" value={editWeights.weight} onChange={(e)=>weightsChange(e.target.value,record.id)} autoFocus ref={weightInputRef} onBlur={()=>updateWeight()} onPressEnter={()=>autoBlur()} /> :
                        <p onClick={()=>setChangeWeightsId(record.id)}>{record.weights}</p>
                    }
                </div>
            )
        },
        {
            title: '图片',
            dataIndex: 'img',
            key: 'img',
            render: (_,record) => (
                <img 
                    src={`${process.env.REACT_APP_API_URL}/${record.img.split(",")[0]}`} 
                    onClick={()=>propertyClick(record)}
                />
            )
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: "status",
            render: (_,record) => (
                <Select 
                    options={items}
                    value={record.status}
                    bordered={false}
                    onChange={(e)=>statusChange(record.id,e)}
                />
            )
        },
        {
            title: "物业名称",
            dataIndex: "name",
            key: "name",
            render: (_,record) => {
                return <span 
                    onClick={()=>propertyClick(record)}
                    className="name"
                    style={{color: "#1890FF"}}
                >{record.name}</span>
            }
        },
        {
            title: ()=>(
                <Select 
                    options={companyList}
                    value={selectCompany}
                    bordered={false}
                    // onChange={(e)=>statusChange(record.id,e)}
                    onChange={(e)=>setSelectCompany(e)}
                    className="companyTable"
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                />
            ),
            dataIndex: "companyName",
            key: "companyName",
            width: 200,
            ellipsis: true
        },
        {
            title: "地址",
            dataIndex: "address",
            key: "address"
        },
        {
            title: "总室数",
            dataIndex: "rooms",
            key: "rooms"
        },
        {
            title: "房租",
            dataIndex: "cost",
            key: "cost"
        },
        {
            title: "面积",
            dataIndex: "area",
            key: "area",
            render: (text,record) => {
                return <div>
                    <span>{record.area}</span>
                    {record.area && <span>㎡</span>}
                </div>
            }
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            key: "createTime"
        },
        {
            title: "操作",
            dataIndex: "action",
            key: "action",
            fixed: 'right',
            render: (_,record) => (
                <Space>
                    <a onClick={()=>changeModal(record.id)}>编辑</a>
                    <a onClick={()=>delModal(record.id)}>移除</a>
                </Space>
            )
        }
    ]

    const autoBlur = () => {
        weightInputRef.current.blur()
    }

    const weightsChange = (val,id) => {
        editWeights.weight = Number(val);
        editWeights.id = id;
        setEditWeights({...editWeights})
    }

    const updateWeight = async () => {
        if (editWeights.weight) {
            await updatePropertyWeights(editWeights)
            .then((res) => {
                if (res.code === 0) {
                    message.success("更新权重成功");
                    editWeights = {
                        weight: '',
                        id: ''
                    }
                    setEditWeights({...editWeights})
                    getList()
                }else{
                    editWeights = {
                        weight: '',
                        id: ''
                    }
                    setEditWeights({...editWeights})
                    message.error("更新权重失败")
                }
            }).catch((err) => {
                
            });
        }
        setChangeWeightsId('')
    }

    const items = [
        {
            value: 1,
            label: "公开中"
        },
        {
            value: 2,
            label: "装修中"
        }
    ]

    const propertyClick = (record) => {
        console.log("row===",record);
        setBreadCol([...breadCol,{
            path: `/room/roomList?${record.id}`,
            breadcrumbName: record.name,
        }])
        navigate(`/room/roomList?${record.id}`)
    }

    const statusChange = (id,val) => {
        confirm({
            title: "是否需要修改这个物业的状态？",
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk(){
                editStatus(id,val)
            }
        })
    } 

    const editStatus = async (id,val) => {
        let obj = {
            id,
            status: val
        }
        await updatePropertyStatus(obj)
        .then((res) => {
            if (res.code === 0) {
                message.success(res.msg)
                setTimeout(()=>{
                    navigate(0)
                },500)
            }else{
                message.error(res.msg)
            }
        }).catch((err) => {
            
        });
    }

    const getCompany = async () => {
        await getAllCompany()
        .then((res) => {
            if (res.code===0 && res.data.list.length > 0) {
                let arr = [{
                    label: "管理公司",
                    value: 0
                }]
                res.data.list.map((e,i)=>{
                    return arr.push({
                        value: e.id,
                        label: e.name
                    })
                })
                companyList = arr
                setCompanyList(arr)
            }
        }).catch((err) => {
            
        });
    }

    const upDateWeight = (id) => {

    }

    const getList = async () => {
        await getPropertyListInfo({
            page,
            pageSize,
            id: selectCompany === 0 ? '' : selectCompany
        })
        .then((res) => {
            if (res.code===0 && res.data.list) {
                allData = res.data.list;
                setAllData([...allData]);
                total = res.data.total
                setTotal(total)
                let list = ListDataDity({
                    type: 'property',
                    data: res.data.list
                });
                listData = list;
                allListData = list;
                setAllListData([...allListData])
                setListData([...listData])
                console.log("listData====>",listData);
            }else {
                listData = [];
                setListData([...listData])
            }
        }).catch((err) => {
            
        });
    }

    const changeModal = (id) => {
        setShowModal(true);
        allData.map((e,i)=>{
            if (e.id === id) {
                rowData = e;
                setRowData({...rowData})
            }
        })
    }

    const delModal = (id) => {
        confirm({
            title: "是否需要删除这这个物业信息？",
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk(){
                delProperty(id)
            }
        })
    }

    const delProperty = async (id) => {
        await deleteProperty({id:id})
        .then((res)=>{
            if (res.code === 0) {
                message.success(res.msg)
                setTimeout(()=>{
                    navigate(0)
                },1000)
            }else{
                message.error("删除失败")
            }
        })
    }

    const companyChange = (val) => {
        checkCompany = val;
        setCheckCompany(checkCompany)
    }
    
    useEffect(()=>{
        let arr = []
        if (checkCompany === 0) {
            listData = allListData;
            setListData([...listData])
        }
        if (allListData.length > 0 && checkCompany !== 0) {
            allListData.map((e,i)=>{
                if (e.company == checkCompany) {
                    arr.push(e)
                }
            })
            listData = arr;
            setListData([...listData])
        }
    },[checkCompany])

    useEffect(()=>{
        getList()
    },[])

    useEffect(()=>{
        getCompany()
    },[])

    const options = [
        {
            label: "公司列表",
            value: 'company'
        }
    ]

    useEffect(()=>{
        if (page && pageSize) {
            getList()
        }
    },[page,pageSize,selectCompany])

    useEffect(()=>{
        if (location) {

        }
    },[location])



    return <div className="PropertyList">
        <div className="list-title">
            {/* <Select 
                options={companyList}
                value={checkCompany}
                onChange={companyChange}
                bordered={false}
            /> */}
            <Breadcrumb 
                separator=">"
                routes={breadCol}
            />
            <div className="title-right">
                <Input 
                    style={{width: 160}}
                />
                <div className="search-icon">
                    <SearchOutlined />
                </div>
                <Button type="primary" onClick={()=>navigate('/property/createProperty')}>新建物业</Button>
            </div>
        </div>
        <DisplayList 
            type='property' 
            listData={listData} 
            listItem={propertyCol} 
            showModal={showModal}
            setShowModal={setShowModal}
            rowData={rowData}
            setBreadCol={setBreadCol}
            breadCol={breadCol}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            total={total}
        />
    </div>
}


