import { Input, InputNumber } from "antd";
import { useEffect, useState } from "react";
import Map from "./Map";
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { uploadImg } from '../../request/api/property';


// import React, { useState, useEffect } from 'react'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'

export default function BasicData (params) {
    const { data, setData, mapData, setMapData, edit, name, html, setHtml } = params;

    // 编辑器内容
    // const [html, setHtml] = useState('')

    // editor 实例
    const [editor, setEditor] = useState(null)                   // JS 语法
    // editor 初始化次数
    let [initNum,setInitNum] = useState(0)


    // 模拟 ajax 请求，异步设置 html
    // useEffect(() => {
    //     setTimeout(() => {
    //         setHtml('<p>hello world</p>')
    //     }, 1500)
    // }, [])

    // 工具栏配置
    const toolbarConfig = { }                        // JS 语法

    // 编辑器配置
    const editorConfig = {                         // JS 语法
        placeholder: '请输入内容...',
        MENU_CONF: {}
    }

    editorConfig.onFocus = (editor) => {
        // if (type === 'news' && edit) {
        //     rowData.news.map((e,i)=>{
        //         if ( e.language === 'zh_CN' ) {
        //             html.html1 = e.content;
        //         } else if ( e.language === 'zh_HK' ) {
        //             html.html2 = e.content;
        //         } else {
        //             html.html3 = e.content;
        //         }
        //     })
        // }
        // setHtml(data.tenant)
        // console.log("data=====",data);
        // setData({...data})
        let num = initNum;
        num = num + 1;
        initNum = num;
        setInitNum(initNum);
        if (initNum === 1) {
            setHtml(html)
        }

        
    }

    editorConfig.onchange = function () {
        // 自动聚焦到编辑器
        editor.focus();
    };

    editorConfig.MENU_CONF['uploadImage'] = {
        async customUpload(file, insertFn) {    
            let url = '';
            let alt = file.name;
            let href = ""
            const formData = new FormData();
            if (file.originFileObj) {
                formData.append("file",file.originFileObj)
            }else{
                formData.append("file",file)
            }
            await uploadImg(formData)
            .then((res) => {
                if (res.code===0) {
                    url = `${process.env.REACT_APP_API_URL}/${res.data[0]}`
                }
            }).catch((err) => {
                
            });
            insertFn(url, alt, href)
        // file 即选中的文件
        // 自己实现上传，并得到图片 url alt href
        // 最后插入图片
        // insertFn(url, alt, href)
        }
    }

    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    const addChange = (val) => {
        data.address = val;
        setData({...data})
    }

    const accessChange = (val) => {
        data.access = val;
        setData({...data})
    }

    const rentS = (val) =>{
        data.rentStart = val;
        setData({...data})
    }

    const rentE = (val) => {
        data.rentEnd = val;
        setData({...data})
    }

    const areaS = (val) => {
        data.areaStart = val;
        setData({...data})
    }

    const areaE = (val) => {
        data.areaEnd = val;
        setData({...data})
    }

    const roomChange = (val) => {
        data.rooms = val;
        setData({...data})
    }

    const editorChange = (editor) => {
        // data.tenant = editor.getHtml();
        // setData({...data})
        setHtml(editor.getHtml())
    }

    // useEffect(()=>{
    //     console.log("ten",data.tenant);
    //     if (data) {
    //         setHtml(data.tenant)
    //     }
    // },[data])

    return <div className="BasicData">
        <div className="property-name property-item">
            <h3 className="name-title">物件名</h3>
            <p className="name-content">{name}</p>
        </div>
        <div className="property-add property-item">
            <h3>所在地</h3>
            <Input placeholder="请输入" value={data.address} onChange={(e)=>addChange(e.target.value)} />
        </div>
        <Map setData={setMapData} data={mapData} />
        <div className="property-desc property-item">
            <h3>导航路线</h3>
            <Input.TextArea 
                value={data.access} 
                autoSize={{minRows: 2}}
                showCount 
                onChange={(e)=>accessChange(e.target.value)} 
                maxLength={300} 
                style={{width: 1200, maxWidth: "90%"}}
            />
        </div>
        <div className="property-material property-item">
            <div className="property-amount">
                <h3>租金</h3>
                <InputNumber placeholder="填写租金范围" value={data.rentStart} onChange={rentS} min={0} />
                <span className="margin">—</span>
                <InputNumber placeholder="填写租金范围" value={data.rentEnd} onChange={rentE} min={0}/>
                <span className="unit">円</span>
            </div>
            <div className="property-area">
                <h3>面积</h3>
                <InputNumber placeholder="请输入" value={data.areaStart} onChange={areaS} min={0} />
                <span className="margin">—</span>
                <InputNumber placeholder="请输入" value={data.areaEnd} onChange={areaE} min={0} />
                <span className="unit">㎡</span>
            </div>
        </div>
        <div className="property-room property-item">
            <h3>总室数</h3>
            <div className="room-content">
                <InputNumber placeholder="请填写" value={data.rooms} onChange={(e)=>roomChange(e)} min={0} />
                <span className="unit">室</span>
            </div>
        </div>
        <div className="tenant-data property-item">
            <h3>備考</h3>
            {/* <MyEditor html={html} setHtml={setHtml} edit={edit} editData={data.tenant}  /> */}
            <div style={{ border: '1px solid #ccc', zIndex: 100, width: '1200px', marginBottom: '50px'}}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{ borderBottom: '1px solid #ccc' }}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={editor => {editorChange(editor)}}
                    mode="default"
                    style={{ height: '400px', overflowY: 'hidden' }}
                />
            </div>
        </div>
    </div>
}


