import '../../style/Company.scss';
import UploadImg from '../../components/Upload/UploadImg';
import { Input, Steps, Button,Segmented, theme, message, InputNumber } from 'antd'
import { useEffect, useState } from 'react';
import { CreateCompanyInfo, updateCompanyInfo } from '../../request/api/company';
import { useNavigate } from 'react-router-dom';
import validator from 'email-validator';

export default function CreateCompany (params) {
    const navigate = useNavigate()

    const { edit, rowData } = params;

    let [data,setData] = useState({
        name: "",
        tel: "",
        address: "",
        manageFee: '',
        fax: '',
        email: '',
        chargeName: ''
    })
    // let [logoHash,setLogoHash] = useState()
    // 邮箱输入框状态
    let [emailState,setEmailState] = useState()

    const valueChange = (type,val) => {
        data[type] = val;
        setData({...data})
        console.log("data===",data);
    }

    const validEmail = () => {
        if (!validator.validate(data.email)) {
            message.error("邮箱格式不正确")
            setEmailState("error")
        }
    }

    const create = async () => {
        if (!validator.validate(data.email)) {
            message.error("邮箱格式不正确")
            setEmailState("error")
            return false
        }
        const dataObj = {
            name: data.name,
            telephone: data.tel,
            address: data.address,
            email: data.email,
            fax: data.fax,
            guarantor: data.chargeName,
            administrationCost: data.manageFee
        }
        await CreateCompanyInfo(dataObj)
        .then((res) => {
            if (res.code===0){
                message.success(res.msg);
                setTimeout(()=>{
                    navigate('/company/companyList');
                },1000)
            }else{
                message.error(res.msg)
            }
        }).catch((err) => {
            
        });
    }

    const editHandle = async () => {
        let obj = {
            id: rowData.id,
            name: data.name,
            address: data.address,
            telephone: data.tel,
            guarantor: data.chargeName,
            administrationCost: data.manageFee,
            fax: data.fax,
            email: data.email
        }
        await updateCompanyInfo(obj)
        .then((res) => {
            console.log("res---",res);
            if (res.code===0) {
                message.success(res.msg);
                setTimeout(()=>{
                    navigate(0);
                },1000)
            }
        }).catch((err) => {
            
        });
    }

    useEffect(()=>{ 
        if (edit) {
            console.log(rowData);
            data.name = rowData.name;
            data.address = rowData.address;
            data.tel = rowData.telephone;
            data.manageFee = rowData.administrationCost;
            data.fax = rowData.fax;
            data.email = rowData.email;
            data.chargeName = rowData.guarantor
            setData({...data})
            // logoHash = rowData.image;
            // setLogoHash(logoHash);
        }
    },[rowData])


    return <div className="CreateCompany">
        <div className="create-title">
            <h1>新增公司</h1>
        </div>
        <div className='create-content'>
            {/* <div className='content-item'>
                <h3>公司logo</h3>
                <UploadImg setHash={setLogoHash} hash={logoHash} type='company' />
            </div> */}
            <div className='content-item'>
                <h3>公司名称</h3>
                <Input className='w980'
                    value={data.name}
                    onChange={(e)=>valueChange("name",e.target.value)}                    
                />
            </div>
            <div className='content-item fee'>
                <h3>管理费</h3>
                <div className='fee-item'>
                    <InputNumber value={data.manageFee} onChange={(e)=>valueChange('manageFee',e)} />
                    <span className='special'>%</span>
                </div>
            </div>
            <div className='content-item'>
                <h3>公司电话</h3>
                <Input className='w980' 
                    value={data.tel}
                    onChange={(e)=>valueChange("tel",e.target.value)}  
                />
            </div>
            <div className='content-item'>
                <h3>传真</h3>
                <Input className='w980' value={data.fax} onChange={(e)=>valueChange('fax',e.target.value)} />
            </div>
            <div className='content-item'>
                <h3>电子邮箱</h3>
                <Input 
                    className='w980' 
                    value={data.email} 
                    onChange={(e)=>valueChange('email',e.target.value)} 
                    onBlur={()=>validEmail()} 
                    status={emailState} 
                    onFocus={()=>setEmailState("")}
                />
            </div>
            <div className='content-item'>
                <h3>担当名</h3>
                <Input className='w980' value={data.chargeName} onChange={(e)=>valueChange('chargeName',e.target.value)} />
            </div>
            <div className='content-item'>
                <h3>公司地址</h3>
                <Input className='w980' 
                    value={data.address}
                    onChange={(e)=>valueChange("address",e.target.value)}  
                />
            </div>
        </div>
        <div className='create-footer'>
            <Button>取消</Button>
            {
                edit ? <Button type='primary' onClick={()=>editHandle()}>更新</Button>
                :
                <Button type='primary' onClick={()=>create()}>创建</Button>
            }
        </div>
    </div>
}


