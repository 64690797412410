import '../../style/Banner.scss'
import { Button, Input, Upload } from 'antd'
import { PlusOutlined } from "@ant-design/icons"
import UploadImg from '../../components/Upload/UploadImg'
import { useState } from 'react'


export default function CreateBanner () {
    const { TextArea } = Input;
    
    let [data,setData] = useState({
        title: "",
        subtitle: "",
        imgHash: ""
    })


    const titleChange = (val) => {
        data.title = val;
        setData({...data})
    }

    const subtitleChange = (val) => {
        data.subtitle = val;
        setData({...data})
    }


    return <div className="CreateBanner">
        <h1 className='title'>新建Banner</h1>
        <div className='content'>
            <div className='upload-img create-item'>
                <p className='subtitle'>图片：</p>
                <div className='upload-box'>
                    <div className='img-box'>
                        <PlusOutlined />
                    </div>
                    <UploadImg type="banner" />
                </div>
            </div>
            <div className='create-item'>
                <p>大标题</p>
                <TextArea showCount maxLength={50}  value={data.title} onChange={(e)=>titleChange(e.target.value)}  autoSize={{ minRows: 1 }} />
            </div>
            <div className='create-item'>
                <p>小标题</p>
                <TextArea showCount maxLength={100}  value={data.subtitle} onChange={(e)=>subtitleChange(e.target.value)}  autoSize={{ minRows: 3 }} />
            </div>
        </div>
        <div className='create-footer'>
            <Button>取消</Button>
            <Button type='primary'>创建</Button>
        </div>
    </div>
}

