import DisplayList from "../../components/DisplayList/DisplayList"
import { useEffect, useState } from "react"
import { getCompanyList, deleteCompanyInfo } from "../../request/api/company"
import { Button, Input, Modal, Select, Space, Table, message } from "antd";
import ListDataDity from "../../utils/ListDataDity"
import { useNavigate } from "react-router-dom";


export default function CompanyList () {
    const { confirm } = Modal
    const navigate = useNavigate()

    //全部数据
    let [allData,setAllData] = useState([])
    // 展示列表数据
    let [listData,setListData] = useState([])
    // 修改Modal
    let [showModal,setShowModal] = useState(false)
    // 修改行数据
    let [rowData,setRowData] = useState({})

    let [page,setPage] = useState(1)
    let [pageSize,setPageSize] = useState(10)
    let [total,setTotal] = useState();


    const companyCol = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: "公司名",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "管理费",
            dataIndex: "administrationCost",
            key: "administrationCost",
            render: (_,record) => {
                return <span>{record.administrationCost}%</span>
            }
        },
        {
            title: "物业件数",
            dataIndex: "estateNum",
            key: "estateNum",
            render: (_,record) => {
                return <div className="estateNum" onClick={()=>navigate(`/property/propertyList?${record.id}`)} style={{cursor: "pointer"}}>
                    <span style={{color: "#1890FF"}}>{record.estateNum}</span>
                </div>
            }
        },
        {
            title: "公司电话",
            dataIndex: "tel",
            key: "tel"
        },
        {
            title: "传真",
            dataIndex: "fax",
            key: "fax"
        },
        {
            title: "电子邮箱",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "担当名",
            dataIndex: "guarantor",
            key: "guarantor"
        },
        {
            title: "公司地址",
            dataIndex: "address",
            key: "address",
            width: 150,
            ellipsis: true
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            key: "createTime"
        },
        {
            title: "操作",
            dataIndex: "action",
            key: "action",
            fixed: 'right',
            render: (_,record) => (
                <Space>
                    <a onClick={()=>changeModal(record.id)}>编辑</a>
                    {record.id === 1 ? "" : <a onClick={()=>delModal(record.id)}>移除</a>}
                </Space>
            )
        }
    ]



    const getList = async () => {
        await getCompanyList({
            page,
            pageSize
        })
        .then((res) => {
            console.log("list====",res);
            if (res.code===0) {
                allData = res.data.list;
                setAllData([...allData]);
                total = res.data.total
                setTotal(total)
                let arr = ListDataDity({
                    type: "company",
                    data: res.data.list
                })
                console.log("arr-=---",arr);
                listData = arr;
                setListData([...listData])
                console.log("listData===",listData);
            }
        }).catch((err) => {
            
        });
    }

    const changeModal = (id) => {
        setShowModal(true);
        allData.map((e,i)=>{
            if (e.id === id) {
                rowData = e;
                setRowData({...rowData})
            }
        })
    }

    const delModal = (id) => {
        confirm({
            title: "是否需要删除这这个公司信息？",
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk(){
                delCompany(id)
            }
        })
    }

    useEffect(()=>{
        console.log("total====>",total);
    },[total])

    const delCompany = async (id) => {
        await deleteCompanyInfo({id:id})
        .then((res)=>{
            if (res.code === 0) {
                message.success(res.msg)
                setTimeout(()=>{
                    navigate(0)
                },1000)
            }else{
                message.error("删除失败")
            }
        })
    }

    useEffect(()=>{
        if (page && pageSize) {
            getList()
        }
    },[page,pageSize])


    return <div className="CompanyList">
        <div className="list-title">
            <h1>公司列表</h1>
        </div>
        <div className="list-content">
            <DisplayList 
                type='company' 
                listData={listData} 
                setListData={setListData} 
                listItem={companyCol}
                showModal={showModal}
                setShowModal={setShowModal}
                rowData={rowData}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={total}
            />
        </div>
    </div>
}


